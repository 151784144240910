import React, { useEffect } from "react";

import HomePageItems from "./cards";
import { pageNotFound, PageTypePath } from "utils/pages";
import Error404 from "../pages/error/Error404";

import RegistrationForm from "../pages/registration/Form";
import RegistrationList from "component/pages/registration/RegistrationList";
import SalesNotesList from "component/pages/salesNote/SalesNotesList";
import LandingNoteList from "component/pages/landingNote/LandingNotesList";
import SalesNoteForm from "component/pages/salesNote/From";
import PdfViewer from "component/pages/pdf/PdfViewer";

import { useParams } from "react-router";
import Settlements from "component/pages/settlement/Settlements";
import Invoice from "component/pages/invoice/Invoice";
import AnnualStatement from "component/pages/annualStatement/AnnualStatement";
import SalesConfirmations from "component/pages/salesConfirmation/SalesConfirmation";
import Contracts from "component/pages/contract/Contract";
import LandingNoteForm from "component/pages/landingNote/From";

const Pages: React.FC<{ currentPage: string }> = ({ currentPage }) => {
	let { path, id, action } = useParams();

	const checkMenuPageName = (page: string) => {
		return currentPage === page;
	};

	useEffect(() => {
		if (!path) {
			return;
		}
	}, []);

	return (
		<div
			style={{
				height: "100%",
				paddingRight: 30,
				paddingLeft: 25,
				// paddingBottom: 50,
			}}
		>
			{checkMenuPageName(PageTypePath.Home) && <HomePageItems />}

			{checkMenuPageName(PageTypePath.RegistrationList) && <RegistrationList />}
			{checkMenuPageName(PageTypePath.RegistrationForm) && <RegistrationForm />}

			{checkMenuPageName(PageTypePath.SalesNotesList) && <SalesNotesList />}
			{checkMenuPageName(PageTypePath.SalesNoteForm) && <SalesNoteForm />}

			{checkMenuPageName(PageTypePath.LandingNoteList) && <LandingNoteList />}
			{checkMenuPageName(PageTypePath.AccountingSettlement) && <Settlements />}
			{checkMenuPageName(PageTypePath.AccountingInvoice) && <Invoice />}
			{checkMenuPageName(PageTypePath.AccountingAnnualReport) && (
				<AnnualStatement />
			)}
			{checkMenuPageName(PageTypePath.SalesConfirmations) && (
				<SalesConfirmations />
			)}
			{checkMenuPageName(PageTypePath.SalesContract) && <Contracts />}
			{checkMenuPageName(PageTypePath.LandingNoteForm) && <LandingNoteForm />}

			{/*{checkMenuPageName(PageTypePath.PurchaseOverview) && <PurchaseOverview />}*/}

			{checkMenuPageName(PageTypePath.Pdf) && <PdfViewer />}

			{!pageNotFound(currentPage) && <Error404 />}
		</div>
	);
};

export default Pages;
