import React, { useRef, useState } from "react";

import styles from "styles.module.css";
import Filter from "./Filter";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { addMonths } from "date-fns";
import OverLayLoading from "ui/Loading";
import GridCustomPage from "ui/grid/GridPage";
import { useTranslation } from "react-i18next";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PageTypePath } from "utils/pages";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";
import { ContractGridColumn } from "./GridColumns";
import {
	contractConfirmationApi,
	ContractFilter,
	getContractsApi,
} from "api/contract";
import { useInfoStore } from "store/error";

const pageSize = 60;

const Contracts = () => {
	const { t } = useTranslation();

	const overlayRef = useRef<HTMLDivElement>(null);

	const gridRef = useRef<HTMLDivElement>(null);

	const [filterOptions, setFilterOptions] = useState<ContractFilter>({
		take: pageSize,
		skip: 0,
		fromDate: addMonths(new Date(), -1),
		toDate: new Date(),
	});

	const [confirmed, setConfirmed] = useState(false);
	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);
	const { setError } = useInfoStore();

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const openPdf = (prop: GridCellProps) => {
		window.open(
			`/${PageTypePath.Pdf}/${prop.dataItem.id}/${PdfType.Contract}`,
			"_blank"
		);
	};

	const contractConfirmation = (prop: GridCellProps) => {
		const _confirmSign = 1; // Todo: this should updated to field in the porps  dataItem

		contractConfirmationApi({
			confirmSign: _confirmSign,
			contractId: prop.dataItem.id,
		})
			.then((response) => {
				setConfirmed(!confirmed);
				console.log();
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	return (
		<div className={styles.PageContainer}>
			<div ref={overlayRef} style={{ display: "none" }}>
				<OverLayLoading />
			</div>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>
					{"salgsbekreftelser"}
				</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{}} ref={gridRef}>
				<GridCustomPage
					dataKey="id"
					gridColumns={ContractGridColumn()}
					// detailPage={DetailedPage}
					filter={filterOptions}
					forceUpdate={confirmed}
					exportGridAsPDFRef={pdfRef}
					lastColWidth="140px"
					lastColBtns={[
						{
							iconName: ICON_NAMES.Confirmation,

							showConditionValue: 1,
							conditionField: "showSign",
							onclick: contractConfirmation,
						},
						{
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
							onclick: openPdf,
						},
					]}
					exportGridAsExcelRef={excelRef}
					apiMethod={getContractsApi}
					showDetailIcon={false}
				/>
			</div>
		</div>
	);
};

export default Contracts;
