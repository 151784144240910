import React, { FC, useEffect, useRef, useState } from "react";

import CustomGrid from "ui/grid/CustomGrid";
import { GridCellProps, GridItemChangeEvent } from "@progress/kendo-react-grid";

import CustomButton from "ui/Button";
import { CLASSNAME, COLOR } from "utils/constant";
import { RegistrationLine } from "interface/registration";
import { Package } from "interface/common";

import Info from "ui/Info";
import { useParams } from "react-router";
import { stepTwoGridColumns } from "../utils/StepTwoGridColumns";
import { useTranslation } from "react-i18next";
import { useInfoStore } from "store/error";
import NoteModal from "../utils/NoteModal";
import {
	deleteLandingNoteLine,
	getLandingNoteLineViewApi,
	saveLandingNoteLine,
} from "api/landingNote";
import { LandingNoteLine } from "interface/landingNote";

interface Form extends LandingNoteLine {
	id: number;
	inEdit: boolean;
}
export const FormStepTwo: FC<{
	form: Form[];
	setForm: (data: Form[]) => void;
	showRequirement: boolean;
	setMeetRequirement: (meetRequirement: boolean) => void;
}> = ({ form, setForm, showRequirement, setMeetRequirement }) => {
	const { t } = useTranslation();
	const { id } = useParams();

	const [showDeleteLine, setShowDeleteLine] = useState(false);
	const [showNoteModal, setShowNoteModal] = useState(false);
	const modalDataRef = useRef<any>();
	const lastChangedPackage = useRef<Package>();
	const deletingLineRef = useRef<LandingNoteLine>();
	const { setError } = useInfoStore();

	useEffect(() => {
		if (!id) {
			return;
		}
		getData();
	}, []);

	const getData = () => {
		getLandingNoteLineViewApi(id as any)
			.then((response) => {
				setForm(response.data.map((line) => ({ ...line, inEdit: true })));
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const updateNoteLine = (_form: Form) => {
		if (!_form?.id) {
			_form.packagingCode = lastChangedPackage.current?.code as any;
			_form.noteHeadId = id as any;
		}
		saveLandingNoteLine({ ..._form })
			.then((data) => {
				let line = {
					...data.data,
					inEdit: true,
				};

				if (!_form.id) {
					setForm([...form, line]);
					return;
				}
				setForm(form.map((data) => (data.id === _form.id ? _form : data)));
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const handleOnChangeItem = (e: GridItemChangeEvent) => {
		if (e.field) {
			e.dataItem[e.field] = e.value;
			updateNoteLine(e.dataItem);
		}
	};

	const startDeletingLine = (props: GridCellProps) => {
		deletingLineRef.current = props.dataItem;
		setShowDeleteLine(true);
	};

	const deleteLine = () => {
		if (!deletingLineRef.current) return;
		deleteLandingNoteLine(deletingLineRef.current.id)
			.then((response) => {
				if (response.returnIsOK) {
					setForm(
						form.filter(
							(item) => item.id !== (deletingLineRef.current?.id as any)
						)
					);
				}
				setShowDeleteLine(!showDeleteLine);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	return (
		<div style={{ width: "100%", maxWidth: "100%" }}>
			{showNoteModal && (
				<NoteModal
					showModal={showNoteModal}
					setShowModal={() => setShowNoteModal(!showNoteModal)}
					gridProps={modalDataRef.current}
				/>
			)}
			<CustomGrid
				style={{ width: "100%" }}
				gridColumns={stepTwoGridColumns({
					update: updateNoteLine,
					onSelectPackage: (data) => {
						lastChangedPackage.current = {
							code: data.packagingCode,
							displayName: "",
							name: data.packaging,
						};
						updateNoteLine(data);
					},
					openNoteModal: (props) => {
						modalDataRef.current = props;
						setShowNoteModal(!showNoteModal);
					},
					startDeletingLine: startDeletingLine,
					data: form || [],
				})}
				data={form || []}
				className={CLASSNAME.TableNumeric + " " + CLASSNAME.HideOverFlow}
				editField="inEdit"
				onItemChange={handleOnChangeItem}
			/>
			<div style={{ marginTop: "" }}>
				<CustomButton
					label={t("NewLine") + " +"}
					onClick={() => {
						updateNoteLine(Object());
					}}
					bgColor={COLOR.Green}
					color="white"
				/>
			</div>

			<Info
				close={() => setShowDeleteLine(!showDeleteLine)}
				title={t("DeleteLine")}
				show={showDeleteLine}
				description={t("DeleteLineConfirmation")}
				actions={[
					{ text: t("Cancel"), btn: () => setShowDeleteLine(!showDeleteLine) },
					{
						text: t("Yes"),
						btn: deleteLine,
						bgColor: "red",
						textColor: "white",
					},
				]}
			/>
		</div>
	);
};

export default FormStepTwo;
