import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import {
	GridCellProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { COLOR } from "utils/constant";
import { FC } from "react";
import { formatNorwegianNumber } from "utils/helper";

// https://www.telerik.com/kendo-react-ui/components/grid/editing/editing-custom/

export enum GridItemStatus {
	Editing = "Editing",
	New = "new",
	Delete = "Delete",
	None = "None",
}
interface EditCommandCellProps extends GridCellProps {
	enterEdit?: (item: any) => void;
	discard?: (item: any) => void;
	remove?: (item: any) => void;
	cancel?: (item: any) => void;
	update?: (item: any) => void;
	add?: (item: any) => void;
	edit?: (item: any) => void;
	useModal?: boolean;
}

//TODO: Review the logic  and update
const GridCommandCell: React.FC<EditCommandCellProps> = (props) => {
	const { dataItem } = props;
	let inEdit = false;
	let isNewItem = false;
	if (dataItem.status) {
		isNewItem = dataItem.status === GridItemStatus.New;
	}

	// manually set item as editing
	//Remove this  after finish testing
	if (props.dataIndex === 0) {
		inEdit = true;
	}
	const [visible, setVisible] = React.useState(false);
	const onDeleteData = () => {
		if (props.remove) {
			props.remove(props.dataItem);
		}
		setVisible(!visible);
	};
	const toggleDialog = () => {
		setVisible(!visible);
	};

	const handleEditUpdateAndAdd = () => {
		if (inEdit) {
			if (isNewItem && props.add) props.add(dataItem);
			if (!isNewItem && props.update) props.update(dataItem);
		} else {
			if (props.edit) props.edit(dataItem);
		}
	};

	const handleDiscardAndCancel = () => {
		if (inEdit) {
			if (isNewItem && props.discard) props.discard(dataItem);
			if (!isNewItem && props.cancel) props.cancel(dataItem);
		} else {
			toggleDialog();
		}
	};
	const Icon: React.FC<{ iconName: string }> = ({ iconName }) => (
		<img src={`/icon/${iconName}.svg`} alt={iconName} style={{}} />
	);
	return (
		<td
			className="k-command-cell "
			style={{ display: "flex", gap: "6px", justifyContent: "flex-end" }}
		>
			{!inEdit && !isNewItem ? (
				<Button
					style={{
						backgroundColor: "transparent",
						padding: "2px",
						border: "none",
						marginRight: "0px",
					}}
					onClick={handleEditUpdateAndAdd}
				>
					{inEdit ? (
						isNewItem ? (
							<Icon iconName="plus" />
						) : (
							<Icon iconName="save" />
						)
					) : (
						<Icon iconName="accept" />
					)}
				</Button>
			) : (
				<>
					<Button
						style={{
							backgroundColor: "transparent",
							padding: "2px",
							border: "none",
						}}
						onClick={handleDiscardAndCancel}
					>
						{/*{inEdit ? (isNewItem ? "Discard" : "Cancel") : <Icon iconName="trash" />}*/}
						{inEdit ? (
							isNewItem ? (
								"Discard"
							) : (
								"Cancel"
							)
						) : (
							<Icon iconName="trash" />
						)}
					</Button>

					<Button
						style={{
							backgroundColor: "transparent",
							padding: "2px",
							border: "none",
						}}
						onClick={handleEditUpdateAndAdd}
					>
						<Icon iconName="edit" />
					</Button>
					<Button
						style={{
							backgroundColor: "transparent",
							padding: "2px",
							border: "none",
						}}
						onClick={handleEditUpdateAndAdd}
					>
						{/*{inEdit ? (isNewItem ? <Icon iconName="plus" /> : <Icon iconName="save" />) : <Icon iconName="edit" />}*/}
						{inEdit ? (
							isNewItem ? (
								<Icon iconName="plus" />
							) : (
								<Icon iconName="save" />
							)
						) : (
							<Icon iconName="edit" />
						)}
					</Button>
				</>
			)}

			{visible && (
				<Dialog title={"Delete Data"} onClose={toggleDialog} width={350}>
					<div>
						Are you sure you want to delete item with ID {dataItem.ProductID}?
					</div>
					<DialogActionsBar>
						<Button onClick={onDeleteData}>Delete</Button>
						<Button onClick={toggleDialog}>Cancel</Button>
					</DialogActionsBar>
				</Dialog>
			)}
		</td>
	);
};

export const CustomGridCommandCell: React.FC<
	GridCellProps & {
		iconName: string;
		text?: string;
		onclick: (data: GridCellProps) => void;
		style?: React.CSSProperties;
	}
> = ({ iconName, onclick, text, ...props }) => {
	const handleClick = () => {
		onclick(props);
	};

	return (
		<td className=" no-border" style={{ marginTop: "4px", ...props.style }}>
			<Button
				style={{
					padding: "2px",
					border: "none",
					marginTop: "auto",
					marginBottom: "auto",
					height: "34px",
					width: "34px",
				}}
				onClick={handleClick}
			>
				{iconName ? (
					<img
						src={`/icon/${iconName}.svg`}
						alt={iconName}
						style={{ height: "16px", width: "16px", margin: "auto" }}
					/>
				) : (
					text
				)}
			</Button>
		</td>
	);
};

export default GridCommandCell;

export const GridCommandCellMultiple: React.FC<
	GridCellProps & {
		Cell?: React.ComponentType<any>;
		items: {
			iconName: string;
			text?: string;
			onclick?: (data: GridCellProps) => void;
			showItem?: boolean;
			className?: string;
			style?: React.CSSProperties;
		}[];
	}
> = ({ items, Cell, ...props }) => {
	const style = {
		// padding: "2px",
		border: "none",
		//margin: "auto",

		height: "34px",
		width: "34px",
		//backgroundColor: COLOR.Grey94,
	};
	const showItem = (showItem: boolean | undefined) => {
		if (showItem === undefined) return true;
		return showItem;
	};
	const handleClick = (fn: any) => {
		if (fn) {
			fn(props);
		}
	};
	return (
		<td
			className="no-border"
			style={{
				marginTop: "4px",
				display: "flex",
				gap: 5,
				//justifyContent: items.length === 1 ? "flex-end" : "space-between",
				justifyContent: "flex-end",
				...props.style,
			}}
		>
			{items.map((command, index) => (
				<Button
					key={index}
					className={props.className}
					style={{
						...style,
						...command.style,
						backgroundColor: "transparent",
						opacity: 1,
						boxShadow: "none",
						display: showItem(command.showItem) ? "" : "none",
					}}
					disabled={!command.onclick}
					onClick={() => handleClick(command.onclick)}
				>
					{command.iconName ? (
						<img
							src={`/icon/${command.iconName}.svg`}
							alt={command.iconName}
							style={{ height: "16px", width: "16px", margin: "auto" }}
						/>
					) : (
						command.text
					)}
				</Button>
			))}

			{Cell && <Cell {...props} />}
		</td>
	);
};

export const NumericalHeader: FC<
	GridHeaderCellProps & { style?: React.CSSProperties }
> = (props) => {
	return <span style={{ ...props.style }}>{props.title}</span>;
};

export const NumericalCell: FC<
	GridCustomCellProps & { unit?: string; formatDecimal?: number }
> = ({ field, dataItem, formatDecimal = 0, unit, style }) => {
	let value;
	if (field) {
		value = dataItem[field];
	}
	return (
		<td style={{ textAlign: "end", border: "none", ...style }}>
			<span>{formatNorwegianNumber(value, formatDecimal, formatDecimal)}</span>{" "}
			{unit && <span>{unit}</span>}
		</td>
	);
};
