import axios from 'axios';
import i18n from "../i18n";

let language = i18n.language;


//const baseURL = process.env.NODE_ENV === 'production'
//    ? process.env.REACT_APP_API_URL
//    : "https://localhost:7283/";

const baseURL = "https://surofiapi.0x1.no/";


export const AxiosApi = axios.create({
    baseURL,
    //timeout: 11000, 
    withCredentials: true,
    // add custom header
    headers: {
        'Language': language,
    }
});


AxiosApi.interceptors.response.use(resp => {
    return resp
}, err => {
    const { responseURL, status } = err.response.request;

    const isRedirect = responseURL.includes("ReturnUrl") && responseURL.includes("login");
    if (isRedirect) {
        //Todo: we dont need this when we are using same site 
        // const redirect = responseURL.split("/login")[1]
        // const loginUrl = `/login/${redirect}`
        if (status === 401) {
            window.location.replace("/login");

        }
        // // Todo: remove this, this is just to handle redirect issue which wont happy when deploy 
        // if (status === 405) {
        //     window.location.replace("/login");
        // }
    }
     
    return Promise.reject(err);
});

