import {
	DropDownList,
	DropDownListChangeEvent,
	DropDownListFilterChangeEvent,
	DropDownListProps,
	ListItemProps,
} from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import {
	CompositeFilterDescriptor,
	FilterDescriptor,
	filterBy,
} from "@progress/kendo-data-query";
import { COLOR } from "../utils/constant";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { useInfoStore } from "store/error";

export interface IDropDownListItem extends DropDownListProps {
	//when passing any[] then  you have define the  textField and dataItemKey
	data: any[];
	//defaultItem should match the values of textField and dataItemKey use
	defaultItem?: any;
	filterable?: boolean;
	filterDelay?: number;
	textField: string;
	dataItemKey: string;
	disabled?: boolean;
	label?: string;
	serverFilter?: boolean;
	showError?: boolean;
	borderColor?: string;
	onSelect?: (data: any) => void;
	serverFilterApi?: (text: string) => Promise<any[]>;
	// pass a function to get data  from api
	getData?: () => void;
}

const CustomDropDownList: React.FC<IDropDownListItem> = ({
	data = [],
	defaultItem,
	serverFilter = true,
	filterable = false,
	value,
	filterDelay = 500,
	getData,
	dataItemKey = "value",
	textField = "text",
	disabled,
	onChange,
	showError = false,
	...props
}) => {
	const [filterItem, setFilterItem] = useState<{
		data: any[] | null;
		loading: boolean;
	}>({ data: null, loading: false });

	const { setError } = useInfoStore();

	const handleChange = (e: DropDownListChangeEvent) => {
		if (onChange) {
			onChange(e);
		}
		if (props.onSelect) {
			props.onSelect(e.target.value);
		}
	};

	const handleGetData = () => {
		// request data from api
		if (filterable) return;
		if (data.length < 1 && getData) {
			getData();
		}
	};

	const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
		if (!data) {
			return filterBy([], filter);
		}
		return filterBy(data, filter);
	};

	const filterChange = (event: DropDownListFilterChangeEvent) => {
		setFilterItem({ ...filterItem, loading: true });

		setTimeout(() => {
			if (serverFilter) {
				if (props.serverFilterApi) {
					props
						.serverFilterApi(event.filter.value)
						.then((data) => {
							setFilterItem({ loading: false, data });
						})
						.catch((err) => {
							if (err?.response?.data) {
								setError(err?.response?.data.returnText);
							} else {
								setError(err.message);
							}
						});
				}
			} else {
				setFilterItem({ loading: false, data: filterData(event.filter) });
			}
		}, filterDelay);
	};
	let bColor = "";
	if (value && Object.keys(value).length > 0) {
		bColor = COLOR.Green;
	}
	if (props.borderColor) {
		bColor = props.borderColor;
	}
	return (
		<div>
			<DropDownList
				disabled={disabled}
				// itemRender={itemRender}
				itemRender={props.itemRender}
				data={filterItem?.data ? filterItem.data : data}
				textField={textField}
				dataItemKey={dataItemKey}
				value={value}
				defaultItem={defaultItem}
				onChange={handleChange}
				filterable={filterable}
				onOpen={handleGetData}
				loading={filterItem.loading}
				onFilterChange={filterChange}
				className={props.className}
				popupSettings={{ width: "auto", ...props.popupSettings }}
				{...props}
				style={{
					backgroundColor: COLOR.Grey98,
					borderColor: showError ? "red" : bColor,
					borderWidth: "1px",
					width: "100%",
				}}
			/>
		</div>
	);
};
export default CustomDropDownList;

// const itemRender = (li: any, itemProps: ListItemProps) => {
// 	console.log(li, itemProps);
// 	const index = itemProps.index;
// 	const itemChildren = (
// 		<span
// 			style={{
// 				color: "#00F",
// 			}}
// 		>
// 			{li.props.children} {index}
// 		</span>
// 	);
// 	return React.cloneElement(li, li.props, itemChildren);
// };
