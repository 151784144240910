import { Grid, GridColumn, GridColumnProps } from "@progress/kendo-react-grid";
import { COLOR } from "utils/constant";
import { CustomRowRender } from "ui/grid/CustomGrid";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfoStore } from "store/error";

interface DetailedPagePros {
	grids: GridColumnProps[];
	dataApi: () => Promise<any>;
	// rowDetails: GridDetailRowProps;
}

const CustomDetailedPage: FC<DetailedPagePros> = ({ grids, dataApi }) => {
	const { t } = useTranslation();

	const [data, setData] = useState<any[]>([]);
	const { setError } = useInfoStore();

	useEffect(() => {
		dataApi()
			.then((data) => {
				setData(data.data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
				console.log(err);
			});
	}, []);

	return (
		<div>
			<Grid
				style={{
					backgroundColor: COLOR.GridOpenedBody,
					height: " fit-content",
				}}
				rowRender={CustomRowRender}
				rowHeight={30}
				data={data}
				className="custom_detail_grid"
			>
				{grids.map((column, key) => (
					<GridColumn key={key} {...column} className="no-border" />
				))}
			</Grid>
		</div>
	);
};

export default CustomDetailedPage;
