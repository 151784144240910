import * as React from "react";
import styles from "../styles.module.css";

import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
	Input,
	MaskedTextBox,
	NumericTextBox,
	Checkbox,
	RadioGroup,
	Slider,
	SliderLabel,
	RangeSlider,
	TextArea,
	Rating,
} from "@progress/kendo-react-inputs";
import {
	DatePicker,
	TimePicker,
	DateTimePicker,
	DateRangePicker,
	DateInput,
} from "@progress/kendo-react-dateinputs";
import {
	Label,
	Error,
	Hint,
	FloatingLabel,
} from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import {
	DropDownList,
	AutoComplete,
	MultiSelect,
	ComboBox,
	MultiColumnComboBox,
	DropDownTree,
} from "@progress/kendo-react-dropdowns";

import CustomDropDownList from "../ui/dropDown";
import { COLOR, DATE_TIME_FORMAT } from "../utils/constant";

interface columnsInterface {
	field: string;
	header: any;
	width: string;
}

export const ItemLabel: React.FC<{
	id: any;
	editorValid?: boolean;
	editorDisabled?: boolean;
	optional?: boolean;
	valid?: boolean;
	text: string;
}> = ({ id, editorDisabled, optional, editorValid, text }) => {
	return (
		<Label
			editorId={id}
			editorValid={editorValid}
			editorDisabled={editorDisabled}
			optional={optional}
			className={styles.InputLabel}
		>
			{text}
		</Label>
	);
};

export const CustomeCheckBox = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		...others
	} = fieldRenderProps;
	return (
		<Checkbox
			label={label}
			labelOptional={optional}
			valid={valid}
			id={id}
			disabled={disabled}
			{...others}
		/>
	);
};

export const FormInput = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		currentValue,
		isValid,
		value,
		saveItem,
		onBlur,
		...others
	} = fieldRenderProps;
	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<ItemLabel
				id={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}
				text={label}
			/>
			<div className={"k-form-field-wrap"}>
				<Input
					valid={isValid}
					onBlur={saveItem}
					type={type}
					id={id}
					value={currentValue || ""}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					{...others}
				/>
			</div>
		</FieldWrapper>
	);
};

export const FormRadioGroup = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		id,
		label,
		valid,
		disabled,
		hint,
		visited,
		modified,
		value,
		selected,
		showErrorMessage,

		...others
	} = fieldRenderProps;

	return (
		<FieldWrapper>
			<ItemLabel
				id={id}
				editorValid={valid}
				editorDisabled={disabled}
				text={label}
			/>
			<div className={"k-form-field-wrap"}>
				<RadioGroup
					className={`${styles.RadioButton} ${
						showErrorMessage ? " error" : ""
					} `}
					style={{ borderRadius: "5px" }}
					valid={valid}
					value={value || selected}
					disabled={disabled}
					{...others}
				/>
			</div>
		</FieldWrapper>
	);
};

export const FormDropDownList = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		value,
		onChange,
		currentValue,
		defaultValue,
		showErrorMessage,
		loadDataOnClicked,
		onSelect,
		...others
	} = fieldRenderProps;
	let bColor = "";
	if (currentValue && Object.keys(currentValue).length > 0) {
		bColor = COLOR.Green;
	}
	const handlerOnChange = (e: any) => {
		if (onSelect) {
			onSelect(e.value);
		}

		onChange(e);
	};
	return (
		<FieldWrapper style={wrapperStyle}>
			<ItemLabel id={id} editorDisabled={disabled} text={label} />
			<div className={`k-form-field-wrap ${value ? "form_dropdown " : ""}`}>
				<DropDownList
					// className={`${showErrorMessage ? "error" : ""}`}
					//  style={{ borderColor: value ? COLOR.Green : "", borderWidth: "2px" }}
					style={{
						backgroundColor: COLOR.Grey98,
						borderColor: showErrorMessage ? "red" : bColor,
						borderWidth: "1px",
					}}
					id={id}
					onChange={handlerOnChange}
					disabled={disabled}
					value={currentValue}
					onOpen={loadDataOnClicked}
					// defaultValue={defaultValue}
					{...others}
				/>
			</div>
		</FieldWrapper>
	);
};

export const FormDropDownListWithSearch = (
	fieldRenderProps: FieldRenderProps & {
		filterApi: (text: string) => Promise<any[]>;
		textField: string;
		dataItemKey: string;
	}
) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		value,
		onChange,
		onSelect,
		data,
		currentValue,
		defaultValue,
		filterApi,
		showErrorMessage,
		...others
	} = fieldRenderProps;
	let _value = value;
	if (!_value) {
		_value = currentValue;
	}
	return (
		<FieldWrapper style={wrapperStyle}>
			<ItemLabel
				id={id}
				editorValid={valid}
				editorDisabled={disabled}
				text={label}
			/>
			<div className={`k-form-field-wrap ${value ? "form_dropdown " : ""}`}>
				<CustomDropDownList
					data={data}
					value={currentValue}
					onChange={onChange}
					onSelect={onSelect}
					defaultValue={defaultValue}
					// defaultItem={}
					serverFilterApi={filterApi}
					disabled={disabled}
					{...others}
					className={`${showErrorMessage ? "error" : ""}`}
					{...others}
				/>
				{showErrorMessage && <Error>{showErrorMessage}</Error>}
			</div>
		</FieldWrapper>
	);
};

export const FormNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<NumericTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormCheckbox = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		id,
		valid,
		disabled,
		hint,
		optional,
		label,
		visited,
		modified,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper>
			<div className={"k-form-field-wrap"}>
				<Checkbox
					ariaDescribedBy={`${hintId} ${errorId}`}
					label={label}
					labelOptional={optional}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormMaskedTextBox = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		optional,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				optional={optional}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<MaskedTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		disabled,
		optional,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				optional={optional}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<TextArea
					valid={valid}
					id={id}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormAutoComplete = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef<any>(null);

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<AutoComplete
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ref={editorRef}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormMultiSelect = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef<any>(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<MultiSelect
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ref={editorRef}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormDatePicker = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		value,
		hint,
		wrapperStyle,
		hintDirection,
		defaultValue,
		currentValue,
		visited,
		modified,
		isValid = true,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";
	let _value = null;
	if (currentValue !== null && currentValue !== undefined) {
		_value = new Date(currentValue);
	}
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<DatePicker
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					value={_value || null}
					id={id}
					// valid={true}
					// valid={isValid}
					className={`${others.className} ${isValid ? "" : " error"}`}
					disabled={disabled}
					defaultValue={defaultValue}
					{...others}
				/>
				{showHint && (
					<Hint id={hintId} direction={hintDirection}>
						{hint}
					</Hint>
				)}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormDateTimePicker = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		value,
		currentValue,
		format = DATE_TIME_FORMAT,
		wrapperStyle,
		...others
	} = fieldRenderProps;

	return (
		<FieldWrapper style={wrapperStyle}>
			<ItemLabel
				id={id}
				editorValid={valid}
				editorDisabled={disabled}
				text={label}
			/>
			<div className={"k-form-field-wrap"}>
				<DateTimePicker
					placeholder={others.placeholder}
					className={`input-border  ${currentValue ? "selected" : ""} `}
					valid={valid}
					id={id}
					value={new Date(currentValue)}
					format={format}
					disabled={disabled}
					{...others}
				/>
			</div>
		</FieldWrapper>
	);
};

export const FormTimePicker = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<TimePicker
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormDateInput = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<DateInput
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormDateRangePicker = (fieldRenderProps: FieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef<any>(null);

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				className="k-form-label"
			>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<DateRangePicker
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ref={editorRef}
					valid={valid}
					id={id}
					disabled={disabled}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormFloatingNumericTextBox = (
	fieldRenderProps: FieldRenderProps
) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		optional,
		value,
		...others
	} = fieldRenderProps;

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper>
			<div className={"k-form-field-wrap"}>
				<FloatingLabel
					optional={optional}
					editorValue={value}
					editorId={id}
					editorValid={valid}
					editorDisabled={disabled}
					label={label}
					labelClassName="k-form-label"
				>
					<NumericTextBox
						ariaDescribedBy={`${hintId} ${errorId}`}
						value={value}
						valid={valid}
						id={id}
						disabled={disabled}
						{...others}
					/>
				</FloatingLabel>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
