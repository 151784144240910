export const COLOR = {
    StatusGreen: "#08A657",
    Green: "#006980",
    Background: "#EDEFF0",
    Grey100:  "#FFFFFF",
    Grey98:  "#F7F9FA",
    Grey94:  "#EDEFF0",
    Grey40:  "#666666",
    Grey90:  "#E3E5E6",
    Grey86:  "#D9DBDC",
    Grey20:  "#333333",
    StatusGrey : "#949494",
    StatusRed : "#A62408",
    StatusBlue: "#3091F2",
    BlueLight: "#43BCD1",
    RedLight: "#EA542E",
    YellowLight: "#F7D277",
    TextLight: "#797E80",
    Text: "#232626",
    Orange :"#ffa500",
    GridOpenedHeader :"#f5fbfb",
    GridOpenedBody :"#fbfcfe"
}
 

export const Font =  {
    WorkSans: "Work Sans",
    Roboto : "Roboto"
}


export const ICON_NAMES = {
    Plus: "plus",
    Arrow: "arrow",
    Home: "home_colored",
    HomeTransparent: "home",
    Delete: "delete",
    More: "more",
    Edit: "edit",
    Settings: "settings",
    SettingsGreen: "settings_green",
    Download: "download",
    Print: "print",
    Search: "search",
    GoBack: "go_back",
    Next: "next",
    NextWhite: "next_white",
    CheckMark:  "checkmark",
    CheckGreen:  "check_green",
    Close: "close",
    CheckboxTransparent: "finish_transparent",
    Fish: "fish",
    FinishTransparent: "finish_transparent",
    Prevous: "prevous",
    CheckmarkGreen: "checkmark_green",
    SurofiTransparent: "surofi_transparent",
    Visibility: "visibility",
    VisibilityOff: "visibility_off",
    GreenCheckbox: "green_checkbox",
    Checkbox: "checkbox_mark",
    ArrowDown : "arrow_down",
    ArrowUp: "arrow_up",
    LeavePage: "leave_page",
    UserAccount: "user_account",
    Language: "language",
    Note: "note",
    Buyer: "house",
    Terminal: "house",
    Vessel: "vessel",
    Info: "info", 
    Confirmation: "checkbox_mark"
}

export const ICON_PATH = {
    accept:"/icon/accept.svg",
    account: "/icon/account.svg",
    accounting:"/icon/accounting.svg",
    arrow: "/icon/arrow.svg",
    company: "/icon/company.svg",
    delete : "/icon/delete.svg",
    download: "/icon/download.svg",
    edit: "/icon/edit.svg",
    fish: "/icon/fish.svg",
    go_back: "/icon/go_back.png",
    home: "/icon/home.svg",
    home_colored: "/icon/home_colored.svg",
    more: "/icon/more.svg",
    new: "/icon/new.svg",
    next: "/icon/next.svg",
    nextWhite: "/icon/next_white.svg",
    notification: "/icon/notification.svg",
    plus: "/icon/plus.svg",
    print: "/icon/print.svg",
    save: "/icon/save.svg",
    search: "/icon/search.svg",
    settings: "/icon/settings.svg",
    statistics: "/icon/statistics.svg",
    surofi: "/icon/surofi.svg",
    trash: "/icon/trash.svg",
    checkmark: "/icon/checkmark.svg",
    checkboxTransparent: "/icon/finish_transparent.svg",
    finishTransparent: "/icon/finish_transparent.svg",
    prevous: "/icon/prevous.svg",
    checkmark_green: "/icon/checkmark_green.svg",
    surofi_transparent: "/icon/surofi_transparent.svg",
    green_checkbox: "/icon/green_checkbox.svg",
    checkbox: "/icon/green_checkbox.svg",

}

export const CLASSNAME = {
    ActiveInput: "active-input",
    TableNumeric : "table-numeric",
    HideOverFlow : "hide_over_flow",
    HoverBackgroundColor : "hover-bg"
}

export const DATE_FORMAT  = "dd.MM.yyyy"
export const DATE_TIME_FORMAT  = "dd.MM.yyyy HH:mm"