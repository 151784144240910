import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { CustomRowRender } from "ui/grid/CustomGrid";
import style from "./style.module.css";
import styles from "styles.module.css";
import { ICON_NAMES } from "utils/constant";
import { addMonths } from "date-fns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TextButton } from "ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PageTypePath } from "utils/pages";
import { gridColumnItemsHandler } from "component/pages/landingNote/utils/GridColumns";
import { getLandingNotesApi } from "api/landingNote";
import { ILandingNote } from "interface/landingNote";

function LandingNoteCard() {
	const { t } = useTranslation();
	const [gridData, setGridData] = useState<ILandingNote[]>();
	const router = useNavigate();

	useEffect(() => {
		getLandingNotesApi({
			take: 3,
			skip: 0,
			fromDate: addMonths(new Date(), -1),
			toDate: new Date(),
		})
			.then((response) => {
				setGridData(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div>
			<Card className={style.CardItem}>
				<CardBody>
					<div className={`${styles.BoxHeadingMedium} ${style.CardBodyHeader}`}>
						<div>{t("LastLandingNotes")}</div>
						<div>
							<TextButton
								text={t("OverViewLandingNotes")}
								onClick={() => router(`/${PageTypePath.LandingNoteList}`)}
								iconName={ICON_NAMES.Arrow}
							/>
						</div>
					</div>
					<div>
						<Grid
							style={{ height: 180 }}
							rowHeight={38}
							rowRender={CustomRowRender}
							data={gridData}
							className={
								styles.tableColgroupColFirstChild + " custom_detail_grid"
							}
						>
							{gridColumnItemsHandler().map((column, key) => (
								<GridColumn key={key} {...column} className="no-border" />
							))}
							{/* <GridColumn
								{...lastColWithBtn({
									width: "100px",
									showDetails: false,
									// items: lastColumItems({ edit: editItem }),
									items: [
										{
											iconName: ICON_NAMES.Edit,
											onclick: openEditPage,
											showConditionValue: 0,
											conditionField: "source",
										},
										{
											iconName: ICON_NAMES.Checkbox,
											onclick: previewPage,
											showConditionValue: 0,
											conditionField: "source",
										},
									],
									showDelete: false,
								})}
							/> */}
						</Grid>
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default LandingNoteCard;
