import {
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
import i18n from "../../../../i18n";
import DotCell from "ui/grid/DotCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const gridColumnItemsHandler = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: t("Date"),
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell value={props.dataItem.source} />,
		},

		{
			field: "noteNumber",
			title: t("Note"),
			width: "110px",
			headerClassName: "no-border",
		},
		// {
		// 	field: "species",
		// 	title: "Product",
		// 	width: "110px",
		// 	headerClassName: "no-border",
		// },

		{ field: "vesselName", title: t("Vessel") },
		{ field: "fishingGear", title: t("FishingGear") },
		{
			field: "landingTime",
			title: t("DeliveryDateShort"),
			width: "110px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.landingTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{
			field: "catchField",
			title: t("CatchArea"),
			width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "catchAreaZone",
			title: t("Zone"),
			width: "80px",
			headerClassName: "no-border",
		},
		{
			field: "netLiveWeightKg",
			title: t("TotalWeight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

// export const NumericalHeader: FC<GridHeaderCellProps> = (props) => {
// 	return <span>{props.title}</span>;
// };

// export const NumericalCell: FC<GridCustomCellProps & { unit: string }> = ({
// 	field,
// 	dataItem,
// 	unit,
// }) => {
// 	let value;
// 	if (field) {
// 		value = dataItem[field];
// 	}
// 	// let color = COLOR.TextLight;
// 	// if (dataItem.status === 0) {
// 	//   color = COLOR.Grey20;
// 	// }
// 	return (
// 		<td style={{ textAlign: "end", border: "none" }}>
// 			<span>{value}</span> {unit && <span>{unit}</span>}
// 		</td>
// 	);
// };

export const landingDetailPageGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},
	{
		field: "lineNumber",
		title: "Linje",
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
		width: "50",
	},

	{ field: "product", title: "Produckt" },
	{
		field: "registeredLiveWeightKg",
		title: "Innmeldt",
		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "numberOfPieces",
		title: "Antall",
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "price",
		title: "Pris",
		width: "120px",
		cell: (props) => <NumericalCell {...props} unit={"kr"} formatDecimal={2} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: "Kvantum",
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: "verdi",
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];

export const LandingNoteDetailGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},
	{
		//this will not be render
		// field: "lineNumber",
		title: t("Line"),
		width: "120px",
		cell: (props) => (
			<span style={{ paddingLeft: "10px" }}>{props.dataItem.lineNumber}</span>
		),
	},

	{ field: "product", title: t("Product") },
	{
		field: "registeredLiveWeightKg",
		title: t("RegisteredWeight"),
		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "numberOfPieces",
		title: t("Pieces"),
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "price",
		title: t("Price"),
		width: "120px",
		cell: (props) => <NumericalCell {...props} unit={"kr"} formatDecimal={2} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: t("Weight"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: t("Value"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];
