import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../../i18n";
import DotCell from "ui/grid/DotCell";
import CellPopover from "ui/grid/DescriptionCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const SalesNoteGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell value={props.dataItem.source} />,
		},

		{
			field: "noteNumber",
			title: t("Note"),
			width: "110px",
			headerClassName: "no-border",
		},
		{
			field: "species",
			title: t("Species"),
			//width: "150px",
			headerClassName: "no-border",
		},
		{
			field: "noteStatusId",
			title: t("Condition"),
			width: "110px",
			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},

		{
			field: "vesselName",
			title: t("Vessel"),
			cell: (props) => <CellPopover {...props} />,
		},
		{ field: "fishingGear", title: t("FishingGear") },
		{
			field: "landingTime",
			title: t("DeliveryDateShort"),
			width: "110px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.landingTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{
			field: "catchField",
			title: t("CatchArea"),
			width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "catchAreaZone",
			title: t("Zone"),
			width: "80px",
			headerClassName: "no-border",
		},
		{
			field: "netLiveWeightKg",
			title: t("TotalWeight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "amount",
			title: t("TotalValue"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const SalesNoteDetailGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},
	{
		//this will not be render
		// field: "lineNumber",
		title: t("Line"),
		width: "120px",
		cell: (props) => (
			<span style={{ paddingLeft: "10px" }}>{props.dataItem.lineNumber}</span>
		),
	},

	{ field: "product", title: t("Product") },
	{
		field: "registeredLiveWeightKg",
		title: t("RegisteredWeight"),
		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "numberOfPieces",
		title: t("Pieces"),
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "price",
		title: t("Price"),
		width: "120px",
		cell: (props) => <NumericalCell {...props} unit={"kr"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: t("Weight"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: t("Value"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];
