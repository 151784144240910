import { AxiosApi} from "./index";
import { Registration, IRegistrationForm, RegistrationLine, RegistrationLineForm } from "interface/registration";
import { ApiResponse } from "interface/response";

export interface RegistrationListParams {
    fromDate?: Date|null;
    toDate?: Date|null;
    registrationId?: number;
    fishingGearId?: number;
    fishingGear?: string
    vesselRegistrationNumber?: string;
    vesselName?: string;
    vesselId?: string;
    catchAreaCode?: string;
    speciesCode?: string;
    species?: string;
    buyerId?: number;
    buyerName?: string;
    skip: number;
    take: number;
  }

  
export async function getCatchRegistrationListApi(params: RegistrationListParams) {
    const apiUri = "Registration/GetList"

    try {
        const registrations = await AxiosApi.get<ApiResponse<Registration[]>>(apiUri, {
            params: {
                ...params,
            }
        })
        return registrations.data
    } catch (error) {
        throw error
    }
}
  
export  async function getCatchRegistrationApi(id: number) {
    const apiUri = "Registration/Get"
   
   try {
      const registrations = await AxiosApi.get<ApiResponse<Registration[]>>(apiUri, {params:{
         id
      }})
      return registrations.data
   } catch (error) {
      throw  error
   }
}



export async function getCatchRegistrationLineApi(registrationId: number, status: number) {
    const apiUri = "Registration/GetLines"

    try {
        const registrationLine = await AxiosApi.get<ApiResponse<RegistrationLine[]>>(apiUri, {
            params: {
                registrationId, status
            }
        })
        return registrationLine.data
    } catch (error) {
        throw error
    }
}

export  async function getRegistrationLineDetailApi(id: number) {
    const apiUri = "Registration/GetLine"
   
   try {
      const registrationLine = await AxiosApi.get<RegistrationLine[]>(apiUri, {params:{
         id
      }})
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}
export  async function createNewRegistrationApi(form: IRegistrationForm) {
    const apiUri = "Registration/new"
   
   try {
      const registrationLine = await AxiosApi.post<ApiResponse<IRegistrationForm>>(apiUri, form)
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}

export async function registrationEditingDataApi(registrationId: number) {
    const apiUri = "Registration/edit"

    try {
        const registration = await AxiosApi.get<ApiResponse<IRegistrationForm>>(apiUri, { params: { registrationId } })
        return registration.data
    } catch (error) {
        throw error
    }
}
export  async function addNewRegistrationLineApi(form: RegistrationLineForm) {
    const apiUri = "Registration/NewLine"
   
   try {
       const registrationLine = await AxiosApi.post<RegistrationLine>(apiUri, form)
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}

export  async function deleteTemporaryRegistrationLineApi(id: number, registrationId: number) {
    const apiUri = "Registration/DeleteLine"
   
   try {
      const registrationLine = await AxiosApi.delete<ApiResponse<RegistrationLine[]>>(apiUri, {params:{id, registrationId}})
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}

export  async function getRegistrationLinesApi(registrationId: number,status: number) {
    const apiUri = "Registration/GetLines"
   
   try {
      const registrationLine = await AxiosApi.get<ApiResponse<RegistrationLine[]>>(apiUri, {params:{ registrationId, status}})
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}

export  async function deleteRegistrationApi(id: number,) {
    const apiUri = "Registration/Delete"
   
   try {
      const registrationLine = await AxiosApi.delete(apiUri, {params:{id}})
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}

export  async function completeRegistrationApi(id: number,) {
    const apiUri = "Registration/Complete"
   
   try {
      const registrationLine = await AxiosApi.post(apiUri, {id})
      return registrationLine.data
   } catch (error) {
      throw  error
   }
}


