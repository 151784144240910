import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index"
import { FilterOption } from "interface/index"
import { SalesConfirmation, SalesConfirmationLine } from "interface/sales"
export interface SalesConfirmationFilter extends  FilterOption{
    buyerLedgerAccountNumber?: string
    buyerId ?: number
    buyerName?: string
}




export async function getSalesConfirmationApi(params: SalesConfirmationFilter) {
    const apiUri = "SalesConfirmation/GetList"

    try {
        const salesNote = await AxiosApi.get<ApiResponse<SalesConfirmation[]>>(apiUri, {
            params: {
                ...params,
            }
        })
        return salesNote.data
    } catch (error) {
        throw error
    }
}
    

export async function confirmSalesConfirmationApi({id, confirmationTypeId, url}:{id: number, confirmationTypeId: number, url?: string}) {
    const apiUri = "SalesConfirmation/Confirm"

    try {
        const salesNote = await AxiosApi.get<ApiResponse<SalesConfirmation[]>>(apiUri, {
            params: {
                id, confirmationTypeId, url
            }
        })
        return salesNote.data
    } catch (error) {
        throw error
    }
}
    
export async function getSalesConfirmationLinesApi({salesId, confirmationTypeId}:{salesId: number, confirmationTypeId: number}) {
    const apiUri = "SalesConfirmation/Lines"

    try {
        const salesNote = await AxiosApi.get<ApiResponse<SalesConfirmationLine[]>>(apiUri, {
            params: {
                salesId, confirmationTypeId
            }
        })
        return salesNote.data
    } catch (error) {
        throw error
    }
}
    