import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
//import styles from "styles.module.css";
import i18n from "../../../i18n";
import { CheckMarkCell } from "ui/grid/CheckMarkCell";
import { compareAsc } from "date-fns";
import CellPopover from "ui/grid/DescriptionCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const InvoiceGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		// {
		// 	field: "",
		// 	title: "",
		// 	width: "30px",
		// 	headerClassName: "no-border",
		// 	cell: (props) => <DotCell value={props.dataItem.status} />,
		// },
		{
			field: "invoiceDate",
			title: "Fakturert",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.invoiceDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
			width: "90px",
		},
		{
			field: "dueDate",
			title: "Forfall",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					style={{
						color: isDueDatePassed(
							props.dataItem.dueDate,
							props.dataItem.remainingValue
						)
							? "red"
							: "",
					}}
					date={props.dataItem.dueDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
			width: 90,
		},
		{
			field: "invoiceNumber",
			title: "Faktura Nr",
			cell: (props) => {
				return (
					<td style={props.style} className={props.className + " no-border"}>
						<span>{`${props.dataItem.invoiceType}  ${props.dataItem["invoiceNumber"]}`}</span>
					</td>
				);
			},
			width: "140px",
			headerClassName: "no-border",
		},
		{
			field: "customerName",
			title: "Beskrivelse",
			//width: "150px",
			headerClassName: "no-border",
			cell: (props) => <CellPopover {...props} />,
		},
		{
			field: "ehfAccessResponse",
			title: "EHF",
			cell: (props) => (
				<CheckMarkCell
					{...props}
					checked={props.dataItem["invoiceSentDate"] !== null ? true : false}
				/>
			),
			width: 60,
		},

		{
			field: "netValue",
			title: "Beløp Eks. MVA",
			width: "120px",
			cell: (props) => (
				<NumericalCell {...props} unit={"kr"} formatDecimal={2} />
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "grossValue",
			title: "Beløp ink mva",
			width: "120px",
			cell: (props) => (
				<NumericalCell {...props} unit={"kr"} formatDecimal={2} />
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "paidValue",
			title: "Betalt",
			width: "120px",
			cell: (props) => (
				<NumericalCell {...props} unit={"kr"} formatDecimal={2} />
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "remainingValue",
			title: "Restbeløp",
			width: "120px",
			cell: (props) => (
				<NumericalCell
					{...props}
					style={{
						color: isDueDatePassed(
							props.dataItem.dueDate,
							props.dataItem.remainingValue
						)
							? "red"
							: "",
					}}
					unit={"kr"}
					formatDecimal={2}
				/>
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

const isDueDatePassed = (dueDate: Date, remainingValue: number) => {
	const result = compareAsc(new Date(dueDate), new Date());
	return result === -1 && remainingValue !== 0;
};
