import { ApiResponse } from "interface/response"
import { ILandingNote, LandingNoteHead,  } from "interface/landingNote"
import { AxiosApi } from "./index"
import { FilterOption } from "interface/index"
import { LandingNoteLine } from "interface/landingNote"
import { Comment } from "component/pages/landingNote/utils"

export interface LandingNoteFilter extends  FilterOption{
    noteNumber?:  number,
    vesselName?: string
    vesselId?: string
    buyerId ?: number,
    buyerName?: string
}

export interface LandingNoteLineFilter{
    noteNumber?:  number,
    source? : number, 
    specieCode?: string
}

export  async function getLandingNotesApi(params : LandingNoteFilter ) {
    const apiUri = "LandingNote/GetList"
   try {
      const registrations = await AxiosApi.get<ApiResponse<ILandingNote[]>>(apiUri, {params:{
         ...params,
      }})
      return registrations.data
   } catch (error) {
      throw  error
   }
}
    
export  async function getLandingNoteLinesApi(params :LandingNoteLineFilter ) {
    const apiUri = "LandingNote/GetLines"
   try {
      const registrations = await AxiosApi.get<ApiResponse<LandingNoteLine[]>>(apiUri, {params:{
         ...params,
      }})
      return registrations.data
   } catch (error) {
      throw  error
   }
}
    
export  async function getLandingNoteVHeadApi(noteNumber :number ) {
    const apiUri = "LandingNote/GetvHead"
   try {
      const registrations = await AxiosApi.get<ApiResponse<LandingNoteHead>>(apiUri, {params:{
         noteNumber 
      }})
      return registrations.data
   } catch (error) {
      throw  error
   }
}
    
export  async function saveLandingNoteHead(saleNoteHead: LandingNoteHead ) {
   const apiUri = "LandingNote/SaveNoteHead"
     
   try {
     const salesNote = await AxiosApi.post<ApiResponse<LandingNoteHead>>(apiUri, saleNoteHead)
     return salesNote.data
  } catch (error) {
     throw  error
  }
}
 
export  async function getLandingNoteLineViewApi(noteNumber :number ) {
   const apiUri = "LandingNote/GetLineView"
     
   try {
     const salesNote = await AxiosApi.get<ApiResponse<LandingNoteLine[]>>(apiUri, {params:{
        noteNumber,
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}


export  async function getSalesNoteHeadCommentsApi(noteHeaderId :number ) {
   const apiUri = "SalesNote/GetNoteHeadComments"
     
   try {
     const salesNote = await AxiosApi.get<ApiResponse<Comment[]>>(apiUri, {params:{
       noteHeaderId,
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function saveNoteHeadComment({noteHeaderId,id, description}:{noteHeaderId :number,id: number, description: string} ) {
   const apiUri = "Landing/SaveNoteHeadComment"
     
   try {
     const salesNote = await AxiosApi.get<ApiResponse<LandingNoteLine[]>>(apiUri, {params:{
       noteHeaderId,id, description
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function deleteNoteHeadComment(id: number ) {
   const apiUri = "LandingNote/DeleteNoteHeadComment"
     
   try {
     const salesNote = await AxiosApi.delete<ApiResponse<any>>(apiUri, {params:{
       id
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}


export  async function getLandingNoteHeadCommentsApi(noteHeaderId :number ) {
   const apiUri = "LandingNote/GetNoteHeadComments"
     
   try {
     const salesNote = await AxiosApi.get<ApiResponse<Comment[]>>(apiUri, {params:{
       noteHeaderId,
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function saveNoteHeadComments({noteHeaderId,id, description}:{noteHeaderId :number,id: number, description: string} ) {
   const apiUri = "LandingNote/SaveNoteHeadComment"
     
   try {
     const salesNote = await AxiosApi.get<ApiResponse<LandingNoteLine[]>>(apiUri, {params:{
       noteHeaderId,id, description
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function saveLandingNoteLine(saleNoteHeadLine: LandingNoteLine ) {
   const apiUri = "LandingNote/SaveNoteLine"
     
   try {
     const salesNote = await AxiosApi.post<ApiResponse<LandingNoteLine>>(apiUri, saleNoteHeadLine)
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function deleteLandingNoteLine(id: number ) {
   const apiUri = "SalesNote/DeleteNoteLine"
     
   try {
     const salesNote = await AxiosApi.delete<ApiResponse<LandingNoteLine>>(apiUri, {params:{
       id
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function SaveLandingNoteLineComment({description, noteLineId, id = 0}:{description: string, noteLineId: number, id: number } ) {
   const apiUri = "SalesNote/SaveNoteLineComment"
     
   try {
     const salesNote = await AxiosApi.get<ApiResponse<LandingNoteLine>>(apiUri, { params:{
       description, noteLineId, id
     }},)
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function getLandingNoteLineComments(noteLineId: number ) {
   const apiUri = "SalesNote/GetNoteLineComments"
      
   try {
     const salesNote = await AxiosApi.get<ApiResponse<Comment[]>>(apiUri, {params:{
       noteLineId
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}

export  async function deleteLandingNoteLineComment(id: number ) {
   const apiUri = "SalesNote/DeleteNoteLineComment"
     
   try {
     const salesNote = await AxiosApi.delete<ApiResponse<any>>(apiUri, {params:{
       id
     }})
     return salesNote.data
  } catch (error) {
     throw  error
  }
}