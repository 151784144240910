import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME, ICON_NAMES } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
//import styles from "styles.module.css";
import i18n from "../../../../i18n";
import DotCell from "ui/grid/DotCell";
import CellPopover from "ui/grid/DescriptionCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const gridColumnItemsHandler = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell value={props.dataItem.status} />,
		},
		{ field: "registrationId", title: t("NumberShort"), width: "90px" },
		{
			field: "registrationDate",
			title: t("Date"),
			width: "110px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.registrationDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{
			field: "registrationDate",
			title: t("Time"),
			width: "80px",

			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.registrationDate}
					formatType={"HH:mm"}
				/>
			),
		},
		{
			field: "numberOfLines",
			title: t("Lines"),
			width: "80px",

			cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "vesselName",
			title: t("Vessel"),
			width: 250,
			cell: (props) => <CellPopover {...props} />,
		},
		{ field: "fishingGear", title: t("FishingGear"), width: "160px" },
		{
			field: "buyerName",
			title: t("Buyer"),
			width: "auto",
			cell: (props) => <CellPopover {...props} />,
		},
		{
			field: "liveWeightKg",
			title: t("TotalWeight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const lastColumItems = ({ edit }: { edit?: (data: any) => void }) => {
	return [
		{
			onclick: edit,
			conditionField: "status",
			iconName: ICON_NAMES.Edit,
			showConditionValue: 0,
		},
		{
			conditionField: "status",
			iconName: ICON_NAMES.GreenCheckbox,
			showConditionValue: 1,
		},
		{
			conditionField: "status",
			iconName: ICON_NAMES.Checkbox,
			showConditionValue: 0,
		},
	];
};

export const registrationDetailGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},
	{
		field: "lineNumber",
		title: t("Line"),
		width: "80px",
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{ field: "product", title: t("Product") },
	{ field: "packaging", title: t("Packaging"), width: "auto" },
	{
		field: "numberOfPieces",
		title: t("Pieces"),
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "liveWeightKg",
		title: t("Weight"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];
