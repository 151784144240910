import { ApiResponse } from "interface/response"
import { SalesNote, SalesNoteHead, SalesNoteLine } from "interface/salesNote"
import { AxiosApi } from "./index"
import { FilterOption } from "interface/index"
import { Comment } from "component/pages/salesNote/utils"
export interface SalesNoteFilter extends  FilterOption{
    noteNumber?:  number,
    vesselHullId? : number, 
    vesselName?: string
    vesselId?: string
    buyerId ?: number
    buyerName?: string
}

export interface SalesNoteLineFilter {
    noteNumber?: number,
    source?: number,
    specieCode?: string
}


export async function getSalesNotesApi(params: SalesNoteFilter) {
    const apiUri = "SalesNote/GetList"

    try {
        const salesNote = await AxiosApi.get<ApiResponse<SalesNote[]>>(apiUri, {
            params: {
                ...params,
            }
        })
        return salesNote.data
    } catch (error) {
        throw error
    }
}
    
export async function getSalesNoteLinesApi(params: SalesNoteLineFilter) {
    const apiUri = "SalesNote/GetLines"

    try {
        const salesNote = await AxiosApi.get<ApiResponse<SalesNoteLine[]>>(apiUri, {
            params: {
                ...params,
            }
        })
        return salesNote.data
    } catch (error) {
        throw error
    }
}
 
export  async function getSalesNoteHeaderApi(noteNumber :number ) {
    const apiUri = "SalesNote/GetvHead"
      
    try {
      const salesNote = await AxiosApi.get<ApiResponse<SalesNoteHead>>(apiUri, {params:{
         noteNumber,
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function getSalesNoteLineViewApi(noteNumber :number ) {
    const apiUri = "SalesNote/GetLineView"
      
    try {
      const salesNote = await AxiosApi.get<ApiResponse<SalesNoteLine[]>>(apiUri, {params:{
         noteNumber,
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}

export  async function saveSalesNoteHead(saleNoteHead: SalesNoteHead ) {
    const apiUri = "SalesNote/SaveNoteHead"
      
    try {
      const salesNote = await AxiosApi.post<ApiResponse<SalesNoteHead>>(apiUri, saleNoteHead)
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function getSalesNoteHeadCommentsApi(noteHeaderId :number ) {
    const apiUri = "SalesNote/GetNoteHeadComments"
      
    try {
      const salesNote = await AxiosApi.get<ApiResponse<Comment[]>>(apiUri, {params:{
        noteHeaderId,
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function saveNoteHeadComments({noteHeaderId,id, description}:{noteHeaderId :number,id: number, description: string} ) {
    const apiUri = "SalesNote/SaveNoteHeadComment"
      
    try {
      const salesNote = await AxiosApi.get<ApiResponse<SalesNoteLine[]>>(apiUri, {params:{
        noteHeaderId,id, description
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
export  async function deleteNoteHeadComment(id: number ) {
    const apiUri = "SalesNote/DeleteNoteHeadComment"
      
    try {
      const salesNote = await AxiosApi.delete<ApiResponse<any>>(apiUri, {params:{
        id
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 


export  async function saveSalesNoteLine(saleNoteHeadLine: SalesNoteLine ) {
    const apiUri = "SalesNote/SaveNoteLine"
      
    try {
      const salesNote = await AxiosApi.post<ApiResponse<SalesNoteLine>>(apiUri, saleNoteHeadLine)
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function deleteSalesNoteLine(id: number ) {
    const apiUri = "SalesNote/DeleteNoteLine"
      
    try {
      const salesNote = await AxiosApi.delete<ApiResponse<SalesNoteLine>>(apiUri, {params:{
        id
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function SaveSalesNoteLineComment({description, noteLineId, id = 0}:{description: string, noteLineId: number, id: number } ) {
    const apiUri = "SalesNote/SaveNoteLineComment"
      
    try {
      const salesNote = await AxiosApi.get<ApiResponse<SalesNoteLine>>(apiUri, { params:{
        description, noteLineId, id
      }},)
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function getSalesNoteLineComments(noteLineId: number ) {
    const apiUri = "SalesNote/GetNoteLineComments"
       
    try {
      const salesNote = await AxiosApi.get<ApiResponse<Comment[]>>(apiUri, {params:{
        noteLineId
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 
export  async function deleteSalesNoteLineComment(id: number ) {
    const apiUri = "SalesNote/DeleteNoteLineComment"
      
    try {
      const salesNote = await AxiosApi.delete<ApiResponse<any>>(apiUri, {params:{
        id
      }})
      return salesNote.data
   } catch (error) {
      throw  error
   }
}
 