import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import HeaderItem from "ui/Header";
import Pages from "./Pages";
import "./styles.css";
import SideMenuBar from "component/menu/SideMenu";
import { useLocation } from "react-router-dom";
import { ErrorModal } from "ui/ErrorInfo";
import { usePageHeight } from "utils/hooks";

const Home = () => {
	const [currentPage, setCurrentPage] = useState<string>("/");

	let { path } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const pageHeight = usePageHeight();

	useEffect(() => {
		if (path) {
			if (currentPage === path) return;
			setCurrentPage(path);
		} else {
			//Todo: add not found page
			setCurrentPage("/");
		}

		sessionStorage.setItem("previousUrl", location.pathname);
	}, [path]);

	// useEffect(() => {
	// 	const updateHeight = () => setPageHeight(window.innerHeight);
	// 	window.addEventListener("resize", updateHeight);

	// 	return () => window.removeEventListener("resize", updateHeight);
	// }, []);

	return (
		<div
			style={{
				backgroundColor: "#EDEFF0",
				// height: "100vh",
				height: `calc(${pageHeight}px - 90px)`,
				width: "100%",
			}}
		>
			<div style={{ height: "40px" }}>{""}</div>
			<div
				style={{
					display: "flex",
					width: "100%",
					// height: "calc(100% - 60px)",
				}}
			>
				<div style={{ width: "250px" }}>
					<div style={{ paddingLeft: "27px" }}>
						<img
							src={"/icon/surofi.svg"}
							alt="surofi"
							style={{ height: "28px" }}
							onClick={() => navigate("/")}
						/>
					</div>
					<div style={{ marginTop: "20px" }}>
						<SideMenuBar currentPage={currentPage} />
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						boxSizing: "border-box",
						width: "calc(100%)",
					}}
				>
					<div style={{ paddingLeft: "30px" }}>
						<HeaderItem />
					</div>
					<div style={{ height: 20 }}>{""}</div>

					<div
						style={{
							// paddingTop: 0,
							width: "100%",
							height: "calc(100% - 60px)",
							// maxHeight: "100%",
						}}
					>
						<Pages currentPage={currentPage} />
					</div>
					<ErrorModal />
				</div>
			</div>
		</div>
	);
};

export default Home;
