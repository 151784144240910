import { COLOR } from "utils/constant";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CustomError, InfoPros } from "interface/index";
import React, { FC, useEffect, useState } from "react";
import CustomButton from "./Button";
import { useInfoStore } from "store/error";
import styles from "styles.module.css";
const ErrorInfo: FC<{ show: boolean; text: string }> = ({ text, show }) => {
	//Todo: add styls
	return (
		<div style={{ color: COLOR.StatusRed, display: show ? "block" : "none" }}>
			<span>{text}</span>
		</div>
	);
};

export default ErrorInfo;

interface InfoData extends InfoPros {
	error?: CustomError;
}
const ErrorModal = () => {
	const [infoData, setInfoData] = useState<InfoData>(Object);

	const { error, info, resetStore } = useInfoStore();
	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				handleClose();
			}
		};

		document.addEventListener("keydown", handleKeyPress);
		// setInfoData(props);

		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, []);

	useEffect(() => {
		if (error) {
			if (process.env.NODE_ENV === "production") {
				setInfoData({
					...infoData,
					show: true,
					title: "Error has occur",
					description: "Something  went wrong",
					actions: [
						{
							text: "Close",
							btn: handleClose,
							// bgColor: "red",
						},
					],
				});
			} else {
				setInfoData({
					...infoData,
					show: true,
					title: "Error has occur",
					description: error.message,
					actions: [
						{
							text: "Close",
							btn: handleClose,
							// bgColor: "red",
						},
					],
				});
			}
		}
	}, [error, info]);

	const handleClose = () => {
		setInfoData({ ...infoData, show: false });
		handleReset();
	};
	const handleReset = () => {
		resetStore();
	};
	return (
		<div>
			{infoData.show && (
				<Dialog
					title={infoData.title}
					onClose={handleClose}
					style={{ minWidth: "400px" }}
					className={"info-error "}
				>
					<p
						style={{
							margin: "25px",
							textAlign: "center",
						}}
					>
						{infoData.description}
					</p>
					<DialogActionsBar>
						{infoData.actions.map((action, index) => (
							<div key={index}>
								<CustomButton
									label={action.text}
									onClick={action.btn}
									disabled={action.disabled || false}
									bgColor={action.bgColor}
									color={action.textColor}
									btnStyle={{
										width: "100%",
										justifyContent: "center",
									}}
								/>
							</div>
						))}
					</DialogActionsBar>
				</Dialog>
			)}
		</div>
	);
};
export { ErrorModal };
