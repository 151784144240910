import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../i18n";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const AnnualStatementGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},

		{
			field: "year",
			title: "År",
			width: "60px",
		},
		{
			field: "customerBusinessRegisterNumber",
			title: "Resontro (Ledger Account)",
			width: "200px",
			// cell: (props) => <NumericalCell {...props} unit={""} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			// headerCell: (props) => <NumericalHeader {...props} />,
		},

		{
			field: "customerName",
			title: "Fartøynavn",
			//width: "150px",
			headerClassName: "no-border",
		},
		{
			field: "vesselOwnerName",
			title: "Eiernavn",
			//width: "150px",
			headerClassName: "no-border",
		},
	];
	return gridItems;
};
