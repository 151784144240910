import React, { FC, useEffect, useRef, useState } from "react";

import CustomGrid from "ui/grid/CustomGrid";
import { GridCellProps, GridItemChangeEvent } from "@progress/kendo-react-grid";

import CustomButton from "ui/Button";
import { CLASSNAME, COLOR } from "utils/constant";
import {
	RegistrationDetail,
	RegistrationLine,
	RegistrationLineForm,
} from "interface/registration";
import { Package } from "interface/common";
import {
	addNewRegistrationLineApi,
	deleteTemporaryRegistrationLineApi,
} from "api/registration";
import Info from "ui/Info";
import { useParams } from "react-router";
import { stepTwoGridColumns } from "../utils/StepTwoGridColumns";
import { useTranslation } from "react-i18next";
import { useInfoStore } from "store/error";

interface RegistrationForm extends RegistrationDetail {
	lines?: RegistrationLineForm[];
}
export const FormStepTwo: FC<{
	form: RegistrationForm;
	setForm: (data: RegistrationForm) => void;
	showRequirement: boolean;
	setMeetRequirement: (meetRequirement: boolean) => void;
}> = ({ form, setForm, showRequirement, setMeetRequirement }) => {
	const { t } = useTranslation();
	const [showDeleteLine, setShowDeleteLine] = useState(false);
	const lastChangedPackage = useRef<Package>();
	const deletingLineRef = useRef<RegistrationLine>();
	const requirementsRef = useRef<boolean[]>([]);
	const { id: registrationId } = useParams();
	const { setError } = useInfoStore();

	useEffect(() => {
		if (!form?.lines) {
			return;
		}
		setMeetRequirement(checkAllRequirement());
	}, [form]);

	const addRegistrationLine = (
		_form: RegistrationLineForm,
		updateSate?: boolean
	) => {
		_form.registrationId = registrationId as any;
		if (!_form.id) {
			_form.packagingCode = lastChangedPackage.current?.code;
			requirementsRef.current[requirementsRef.current.length] = false;
		}
		addNewRegistrationLineApi({ ..._form })
			.then((data) => {
				if (updateSate === false) {
					return;
				}
				let lines = form.lines;

				if (lines) {
					const existingLineIndex = lines.findIndex(
						(line) => line.id === data.id
					);
					if (existingLineIndex === -1) {
						lines.push({ ...data, inEdit: true });
					} else {
						lines[existingLineIndex] = { ...data, inEdit: true };
					}
				} else {
					lines = [{ ...data, inEdit: true }];
				}

				setForm({ ...form, lines });
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const handleOnChangeItem = (e: GridItemChangeEvent) => {
		if (e.field) {
			e.dataItem[e.field] = e.value;
			const updateState = false;
			addRegistrationLine(e.dataItem, updateState);
		}
	};
	const meetRequirement = (data: any, field: string) => {
		let isPassed = false;
		if (data[field] != null && data[field] !== "") {
			isPassed = true;
		}

		if (data[field] === 0) {
			isPassed = false;
		}

		if (data[field] === "null") {
			isPassed = false;
		}
		return isPassed;
	};

	const checkAllRequirement = () => {
		if (!form.lines || form.lines.length === 0) {
			return false;
		}

		for (const line of form.lines) {
			if (
				line.productId === 0 ||
				line.packagingCode === "" ||
				line.packagingCode == null ||
				line.price === 0 ||
				line.registrationLiveWeightKg === 0
			) {
				return false;
			}
		}

		return true;
	};

	const startDeletingLine = (props: GridCellProps) => {
		deletingLineRef.current = props.dataItem;
		setShowDeleteLine(true);
	};

	const deleteLine = () => {
		if (!deletingLineRef.current) return;
		deleteTemporaryRegistrationLineApi(
			deletingLineRef.current.id,
			deletingLineRef.current.registrationId
		)
			.then((response) => {
				if (response.returnIsOK) {
					setForm({
						...form,
						lines: form.lines?.filter(
							(item) => item.id !== (deletingLineRef.current?.id as any)
						),
					});
				}
				setShowDeleteLine(!showDeleteLine);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	return (
		<div style={{ width: "100%", maxWidth: "100%" }}>
			<CustomGrid
				style={{ width: "100%" }}
				gridColumns={stepTwoGridColumns({
					addLine: addRegistrationLine,
					showError: showRequirement,
					hasMeetRequirement: meetRequirement,
					onSelectPackage: (data) => {
						lastChangedPackage.current = {
							code: data.packagingCode,
							displayName: "",
							name: data.packaging,
						};
						addRegistrationLine(data);
					},
					startDeletingLine: startDeletingLine,
					data: form.lines || [],
				})}
				data={form.lines || []}
				className={CLASSNAME.TableNumeric + " " + CLASSNAME.HideOverFlow}
				editField="inEdit"
				// onRowClick={handleOnEdit}
				onItemChange={handleOnChangeItem}
				// onExpandChange={(e) => console.log(e)}
			/>
			<div style={{ marginTop: "" }}>
				<CustomButton
					label={t("NewLine") + " +"}
					onClick={() => {
						addRegistrationLine(Object());
					}}
					bgColor={COLOR.Green}
					color="white"
				/>
			</div>
			<Info
				close={() => setShowDeleteLine(!showDeleteLine)}
				title={t("DeleteLine")}
				show={showDeleteLine}
				description={t("DeleteLineConfirmation")}
				actions={[
					{ text: t("Cancel"), btn: () => setShowDeleteLine(!showDeleteLine) },
					{
						text: t("Yes"),
						btn: deleteLine,
						bgColor: "red",
						textColor: "white",
					},
				]}
			/>
		</div>
	);
};

export default FormStepTwo;
