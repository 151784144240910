import { ApiResponse } from "interface/response"
import { AxiosApi } from "./index";
import { AnnualStatement, Invoice, InvoiceYearlyPayment, Settlement, SettlementLine } from "interface/accounting";
import { FilterOption } from "interface";

export interface SettlementFilter extends  Omit<FilterOption, "vesselRegistrationNumber" >{
    vesselLedgerAccountNumber? : string,
     settlementNumber?:   string
}
 
export interface InvoiceFilter extends  Omit<FilterOption, "vesselRegistrationNumber" >{
    buyerLedgerAccountNumber? : string,
    buyerId?: string,
    buyerName?: string,
     invoiceNumber?:   string
}
export interface AnnualStatementFilter extends  Omit<FilterOption, "vesselRegistrationNumber" >{
    vesselLedgerAccountNumber? : string,
    vesselName? : string
    vesselId?: string
}


export async function getSettlementsApi( filter: SettlementFilter  ) {
    const apiUri = "Accounting/Settlements"

    try {
        const settlements = await AxiosApi.get<ApiResponse<Settlement[]>>(apiUri, {
            params: filter
        })
        return settlements.data
    } catch (error) {
 
        throw error
    } 
}
export async function getSettlementLinesApi( settlementId: string ) {
    const apiUri = "Accounting/SettlementLines"

    try {
        const settlementsLines = await AxiosApi.get<ApiResponse<SettlementLine[]>>(apiUri, {
            params: {
                settlementId
            }
        })
        return settlementsLines.data
    } catch (error) {
        throw error
    } 
}




///-------------- Invoice ------ //////

export async function getInvoicesApi( filter: InvoiceFilter   ) {
    const apiUri = "Accounting/Invoices"

    try {
        const invoices = await AxiosApi.get<ApiResponse<Invoice[]>>(apiUri, {
            params: filter
        })
        return invoices.data
    } catch (error) {
        throw error
    } 
}

export async function getInvoicedYearPaymentApi( year?:number   ) {
    const apiUri = "Accounting/InvoicesYearlyPayment"

    try {
        const invoices = await AxiosApi.get<ApiResponse<InvoiceYearlyPayment>>(apiUri, {
            params: {year}
        })
        return invoices.data
    } catch (error) {
        throw error
    } 
}


////---------------  Annual Statement --------- //////

export async function getAnnualStatementApi( filter:AnnualStatementFilter  ) {
    const apiUri = "Accounting/AnnualStatements"

    try {
        const invoices = await AxiosApi.get<ApiResponse<AnnualStatement[]>>(apiUri, {
            params: filter
        })
        return invoices.data
    } catch (error) {
        throw error
    } 
}
