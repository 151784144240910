import {
	GridColumnProps,
	GridCustomCellProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { COLOR } from "utils/constant";
import { FC } from "react";
import i18n from "../../../i18n";
import CellPopover from "ui/grid/DescriptionCell";
import { formatNorwegianNumber } from "utils/helper";
import DotCell from "ui/grid/DotCell";

let t = i18n.t;

export const ContractGridColumn = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "id",
			title: "Salgs nr",
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell color={handleColor(props.dataItem.statusId)} />,
		},
		{
			field: "id",
			title: "Nr",

			width: "100px",
		},
		{
			field: "registrationId",
			title: "Meldingsnummer",
			width: "130px",
		},
		{
			field: "packageId",
			title: "Pakke",
			width: "100px",
		},
		{
			field: "status",
			title: "Status",

			width: "100px",
		},
		{
			field: "buyerLedgerAccountNumber",
			title: "Kjøpernr",
			width: "100px",
		},

		{
			field: "buyerName",
			title: "Kjøpernavn",
			cell: (props) => <CellPopover {...props} />,
		},
		{
			field: "vesselRegistrationId",
			title: "Fartøymerke",
			width: "150px",
		},

		{
			field: "vesselName",
			title: "Fartøynavn",
			cell: (props) => <CellPopover {...props} />,
			width: "170px",
		},
		{
			field: "information",
			title: "Info",
			cell: (props) => <CellPopover {...props} />,
		},
	];
	return gridItems;
};

const handleColor = (value: number) => {
	if (value === 5) {
		return COLOR.StatusGreen;
	}
	if (value === 4) {
		return COLOR.Orange;
	}
	return "red";
};
