import React from "react";
import { Font } from "utils/constant";

function Notification() {
	return (
		<div>
			<div>
				<img
					src={"/icon/notification.svg"}
					alt="notification"
					style={{ width: "25.6px", height: "21.2px" }}
				/>
				<span style={{ fontFamily: Font.WorkSans }}>Varsler</span>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					marginTop: "10px",
					fontStyle: "italic",
					fontSize: "16px",
					fontFamily: Font.Roboto,
				}}
			>
				<div>
					<span>
						<img
							src={"/icon/info.svg"}
							alt="information"
							style={{ width: "16px", height: "16px" }}
						/>
					</span>
					<span> Du har 2 innmelinger som ikke er fullført.</span>
				</div>
				<div>
					<span>
						<img
							src={"/icon/info.svg"}
							alt="information"
							style={{ width: "16px", height: "16px" }}
						/>
					</span>
					<span> Du har mottatt 1 ny avregning.</span>
				</div>
			</div>
		</div>
	);
}

export default Notification;
