import { AxiosApi } from "api"
import { ApiResponse } from "interface/response"
import { BuyerStatistic, VesselStatistic } from "interface/statistic"



export async function getBuyerStatisticApi({ buyerId, year}: { buyerId: number, year: number}) {
    const apiUri = "Statistic/Buyer"
    try {
        const buyer = await AxiosApi.get< ApiResponse<BuyerStatistic>>(apiUri, {
            params: {
                buyerId, year
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}

export async function getVesselStatisticApi({ vesselId, vesselRegistrationNumber, year}: {vesselId: string, vesselRegistrationNumber: string, year: number}) {
    const apiUri = "Statistic/Vessel"
    try {
        const buyer = await AxiosApi.get< ApiResponse<VesselStatistic>>(apiUri, {
            params: {
                vesselId, vesselRegistrationNumber, year
            }
        })
        return buyer.data
    } catch (error) {
        throw error
    }
}
