import React, { useEffect, useState } from "react";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import styles from "styles.module.css";
import CustomButton from "ui/Button";
import { COLOR, ICON_NAMES } from "utils/constant";
import CustomStepper from "ui/CustomStepper";
import FormStepOne from "./form/StepOne";
import FormStepThree from "./form/StepThree";
import { useNavigate, useParams } from "react-router";
import { SalesNoteHead, SalesNoteLine } from "interface/salesNote";
import FormStepTwo from "./form/StepTwo";
import { PageTypePath } from "utils/pages";
import { getLandingNoteVHeadApi } from "api/landingNote";
import { useInfoStore } from "store/error";
import { usePageHeight } from "utils/hooks";

interface LineForm extends SalesNoteLine {
	id: number;
	inEdit: boolean;
}

function LandingNoteForm() {
	const [step, setStep] = useState(0);
	const [allRequirementsMet, setAllRequirementsMet] = useState(false);
	const [showRequirementMetError, setShowRequirementMetError] = useState(false);
	const [form, setForm] = useState<SalesNoteHead>(Object());
	const [lineForm, setLineForm] = useState<LineForm[]>([]);
	const { id, action } = useParams();
	const navigate = useNavigate();

	const pageHeight = usePageHeight();

	const { setError } = useInfoStore();

	const maxStep = 2;

	const stepsItems = [
		{ label: "Fangstopplysninger", optional: false },
		{ label: "Varelinjer", optional: false },
		{ label: "Notatlinjer", optional: true },
	];

	const onSubmit = () => {};

	const handleDelete = () => {};

	const handleFinish = () => {
		handleNext();
	};

	const handleNext = () => {
		if (!allRequirementsMet) {
			setShowRequirementMetError(true);
			return;
		}
		setStep(step + 1);
		setShowRequirementMetError(false);
	};

	useEffect(() => {
		if (!id) {
			return;
		}
		getLandingNoteVHeadApi(id as any)
			.then((data) => {
				setForm(data.data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	}, []);

	useEffect(() => {
		if (!action) {
			return;
		}
		const _step = parseInt(action);
		if (!Number.isNaN(_step) && _step <= maxStep) {
			setStep(_step);
		}
	}, []);

	useEffect(() => {
		if (!action) {
			navigate(`/${PageTypePath.LandingNoteForm}/${id}/0`);
			return;
		}

		const _step = parseInt(action);

		if (!Number.isNaN(_step) && _step <= maxStep) {
			if (step !== _step) {
				navigate(`/${PageTypePath.LandingNoteForm}/${id}/${step}`, {
					replace: true,
				});
			}
		}
	}, [step]);

	return (
		<div className={styles.PageContainer}>
			<legend
				className={styles.BoxHeadingLarge}
				style={{ paddingBottom: "20px" }}
			>
				Edit Landing Note
			</legend>
			<CustomStepper
				steps={stepsItems}
				activeStep={step}
				setActiveStep={setStep}
			/>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					justifyContent: "space-between",
				}}
			>
				<div style={{}}>
					<Form
						onSubmit={onSubmit}
						render={(formRenderProps: FormRenderProps) => (
							<FormElement
								style={{
									height: `calc(${pageHeight}px - 400px)`,
									overflow: "auto",
								}}
							>
								<fieldset className={"k-form-fieldset"}>
									{step === 0 && (
										<FormStepOne
											form={form}
											setForm={setForm}
											showRequirementMetError={showRequirementMetError}
											setAllRequirementsMet={setAllRequirementsMet}
										/>
									)}
									{step === 1 && (
										<FormStepTwo
											setForm={setLineForm}
											form={lineForm}
											showRequirement={false}
											setMeetRequirement={function (
												meetRequirement: boolean
											): void {
												throw new Error("Function not implemented.");
											}}
										/>
									)}
									{step === 2 && <FormStepThree noteHeaderId={id as any} />}
								</fieldset>
							</FormElement>
						)}
					/>
				</div>

				<div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flex: 1,
						}}
					>
						<CustomButton
							onClick={() => setStep(step - 1)}
							label={"Tilbake"}
							iconName={ICON_NAMES.Prevous}
							iconRight={false}
							disabled={step === 0}
						/>

						{step === 2 && (
							<>
								<CustomButton
									onClick={handleFinish}
									label={"Fullfør"}
									bgColor={COLOR.Green}
									color={"white"}
									iconName={ICON_NAMES.FinishTransparent}
								/>
							</>
						)}
						{step < 2 && (
							<CustomButton
								onClick={handleNext}
								label={"Neste"}
								bgColor={COLOR.Green}
								color={"white"}
								iconName={ICON_NAMES.NextWhite}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default LandingNoteForm;
