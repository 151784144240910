import React, { FC, useEffect, useState } from "react";

import { Field } from "@progress/kendo-react-form";
import {
	FormInput,
	FormDropDownList,
	FormRadioGroup,
	FormDateTimePicker,
	FormDropDownListWithSearch,
} from "ui/Topography";

import {
	getBuyersFilterApi,
	getFishingGearApi,
	getCatchAreaApi,
	getQuaySalePortApi,
	getTerminalsApi,
	getVesselByIdApi,
	getVesselFilter,
} from "api/common";

import { Buyer } from "interface/buyer";
import { Vessel } from "interface/vessel";
import { IRegistrationForm } from "interface/registration";
import { useNavigate, useParams } from "react-router";
import { CatchArea, DropDownItem, FishingGear } from "interface/common";

import { createNewRegistrationApi } from "api/registration";
import { Input } from "@progress/kendo-react-inputs";
import { format } from "date-fns";
import { addDays } from "date-fns/addDays";
import { DATE_TIME_FORMAT } from "utils/constant";
import { useTranslation } from "react-i18next";
import { useInfoStore } from "store/error";
import VesselRenderItem from "ui/dropdownItemList/VesselRenderItem";

const FormStepOne: FC<{
	form: IRegistrationForm;
	setForm: (reg: IRegistrationForm) => void;
	showRequirement: boolean;
	setMeetRequirement: (meetRequirement: boolean) => void;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({ form, setForm, showRequirement, setMeetRequirement, setStep }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id: registrationId } = useParams();
	const { setError } = useInfoStore();

	// const [loading, setLoading] = useState(false);
	const [showVesselError, setShowVesselError] = useState(false);

	const [requirement, setRequirement] = useState({
		vessel: false,
		receiver: false,
		quaySaleLocation: false,
		receiverType: false,
	});
	const [ports, setPorts] = useState<DropDownItem[]>([]);

	const [selectedVessel, setSelectedVessel] = useState<Vessel>();

	useEffect(() => {
		if (form?.vesselId) {
			getVessel(form.vesselId);
		}
	}, []);

	useEffect(() => {
		handleCheckRequirement();
	}, [form]);

	const handleCreateRegistration = (form: IRegistrationForm) => {
		if (!form.vesselId) {
			setShowVesselError(true);
			return;
		}
		createNewRegistrationApi(form as any)
			.then((response) => {
				setForm({ ...form, ...response.data });
				if (form?.id) {
					return;
				}
				navigate("./" + response.data.id, {
					state: {
						id: response.data.id,
					},
				});
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const getVessel = (vesselId: string) => {
		getVesselByIdApi(vesselId)
			.then((response) => {
				setSelectedVessel(response);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const vesselSelected = (vessel: Vessel) => {
		setSelectedVessel(vessel);
		const _form: IRegistrationForm = {
			...form,
			vesselId: vessel.id.toString(),
			vesselName: vessel.nm,
			vesselRegistrationNumber: vessel.rn,
			vesselAccountNo: vessel.res?.toString(),
			vesselSource: vessel.sc,
			quaySaleLocationId: null as any,
			quaySalesLocation: null as any,
		};
		setShowVesselError(false);

		if (!form?.id) {
			handleCreateRegistration({ ..._form });

			return;
		}
		handleCreateRegistration({ ..._form });
	};

	const handleRegistrationType = (type: number) => {
		handleCreateRegistration({
			...form,
			noteType: type,
			buyerId: null as any,
			buyerName: "",
			terminalId: "",
			terminalName: "",
		});
		setStep(0);
	};

	const handleQuayOption = (value: number) => {
		if (value === 1) {
			setForm({
				...form,
				isQuaySale: value,
				quaySaleLocationId: null as any,
				quaySalesLocation: null as any,
			});
			return;
		}
		handleCreateRegistration({
			...form,
			isQuaySale: value,
			quaySaleLocationId: null as any,
			quaySalesLocation: null as any,
		});
	};
	const getPorts = () => {
		if (ports && ports.length > 0) return;

		getQuaySalePortApi({ vesselId: form.vesselId as any })
			.then((data) => {
				setPorts([...data, ...ports]);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};
	const handleQuaySelect = (quay: DropDownItem) => {
		handleCreateRegistration({
			...form,
			quaySaleLocationId: quay.code as any,
			quaySalesLocation: quay.name,
		});
	};

	const handleCheckRequirement = () => {
		if (!form) return;
		const _requirement = requirement;
		if (!form?.vesselId) {
			_requirement.vessel = false;
		} else {
			_requirement.vessel = true;
		}

		if (form?.buyerId || form?.terminalId) {
			_requirement.receiver = true;
		} else {
			_requirement.receiver = false;
		}

		if (form?.noteType === 0 || form?.noteType === 1) {
			_requirement.receiverType = true;
		} else {
			_requirement.receiverType = false;
		}

		if (form.isQuaySale === 1 && !form.quaySaleLocationId) {
			_requirement.quaySaleLocation = false;
		} else {
			_requirement.quaySaleLocation = true;
		}

		setMeetRequirement(
			_requirement.quaySaleLocation &&
				_requirement.receiver &&
				_requirement.vessel &&
				_requirement.receiverType
		);
		setRequirement({
			..._requirement,
		});
	};

	return (
		<>
			<Field
				id={"fartoy"}
				name={"fartoy"}
				label={t("Vessel") + "*"}
				data={[]}
				textField={"dn"}
				dataItemKey={"id"}
				defaultItem={{
					dn: t("ChooseVessel"),
					id: null,
				}}
				currentValue={{
					id: form?.vesselId ? form.vesselId : null,
					dn: form?.vesselId
						? form?.vesselRegistrationNumber + " | " + form?.vesselName
						: t("ChooseVessel"),
				}}
				filterable={true}
				filterApi={getVesselFilter}
				itemRender={VesselRenderItem}
				component={FormDropDownListWithSearch}
				onChange={(e) => vesselSelected(e.value)}
				showErrorMessage={
					(showRequirement && !requirement.vessel) || showVesselError
				}
			/>
			{form?.id && (
				<>
					{selectedVessel?.qsId && (
						<Field
							id={"Kaisalg"}
							name={"Kaisalg "}
							label={t("QuaySale") + "*"}
							selected={form.isQuaySale}
							layout={"horizontal"}
							component={FormRadioGroup}
							onChange={(e) => {
								handleQuayOption(e.value);
							}}
							data={[
								{ label: t("Yes"), value: 1 },
								{ label: t("No"), value: 0 },
							]}
						/>
					)}

					{form?.isQuaySale === 1 && (
						<Field
							id={"Kaisalg_sted"}
							name={"Kaisalgssted"}
							label={t("QuaySaleLocation")}
							data={ports}
							textField={"displayName"}
							dataItemKey={"code"}
							currentValue={{
								displayName: form?.quaySalesLocation
									? form.quaySalesLocation
									: t("ChoseQuaySaleLocation"),
								code: form?.quaySaleLocationId ? form.quaySaleLocationId : null,
							}}
							defaultItem={{
								displayName: t("ChoseQuaySaleLocation"),
								code: null,
							}}
							loadDataOnClicked={getPorts}
							onChange={(e) => handleQuaySelect(e.value)}
							component={FormDropDownList}
							showErrorMessage={
								showRequirement && !requirement.quaySaleLocation
							}
						/>
					)}
					<Field
						id={"Mottaker"}
						name={"Mottaker"}
						label={t("Receiver") + "*"}
						textField={"label"}
						selected={form?.noteType}
						loadVesselOnClicked
						layout={"horizontal"}
						component={FormRadioGroup}
						onChange={(e) => handleRegistrationType(e.value)}
						data={[
							{ label: t("Buyer"), value: 0 },
							{ label: t("Terminal"), value: 1 },
						]}
						showErrorAMessage={showRequirement && !requirement.receiverType}
					/>

					{form?.noteType === 1 && (
						<Field
							id={"Leveringssted-ID"}
							name={"Leveringssted-ID"}
							label={t("Terminal") + "*"}
							textField={"name"}
							dataItemKey={"code"}
							filterable={true}
							// onChange={(value: DropDownListChangeEvent) => console.log(value)}
							filterApi={getTerminalsApi}
							defaultItem={{
								name: t("ChooseTerminal"),
								code: null,
							}}
							defaultValue={{
								name: t("ChooseTerminal"),
								code: null,
							}}
							currentValue={{
								code: form?.terminalId ? form.terminalId : null,
								name: form?.terminalName
									? form.terminalName
									: t("ChooseTerminal"),
							}}
							onSelect={(terminal: DropDownItem) =>
								handleCreateRegistration({
									...form,
									terminalId: terminal.code as any,
									terminalName: terminal.name,
									buyerName: null as any,
									buyerId: null as any,
								})
							}
							component={FormDropDownListWithSearch}
							showErrorMessage={showRequirement && !requirement.receiver}
						/>
					)}
					{form?.noteType === 0 && (
						<Field
							id={"Kjøper"}
							name={"Kjøper"}
							label={t("Buyer") + "*"}
							textField={"name"}
							dataItemKey={"id"}
							defaultItem={{
								name: t("ChooseBuyer"),
								id: null,
							}}
							filterable={true}
							currentValue={{
								id: form?.buyerId ? form.buyerId : null,
								name: form?.buyerName ? form.buyerName : t("ChooseBuyer"),
							}}
							filterApi={getBuyersFilterApi}
							onSelect={(buyer: Buyer) =>
								handleCreateRegistration({
									...form,
									buyerId: buyer.id,
									buyerName: buyer.name,
									terminalId: null as any,
									terminalName: null as any,
								})
							}
							component={FormDropDownListWithSearch}
							showErrorMessage={showRequirement && !requirement.receiver}
						/>
					)}

					<Field
						id={"DeliveryDate"}
						name={"Leveringsdato og tidspunkt"}
						label={t("DeliveryDateAndTime")}
						placeholder=""
						currentValue={form.deliveryDate}
						min={addDays(new Date(), -1)}
						dateInput={() => <CustomDateInput form={form} setForm={setForm} />}
						onChange={(e) => {
							handleCreateRegistration({
								...form,
								deliveryDate: e.value,
							});
						}}
						component={FormDateTimePicker}
					/>
					<Field
						id={"Redskap"}
						name={"Redskap"}
						label={t("FishingGear")}
						value={t("ChooseFishingGear")}
						filterable={true}
						textField={"displayName"}
						dataItemKey={"id"}
						filterApi={getFishingGearApi}
						defaultItem={{
							displayName: t("ChooseFishingGear"),
							id: null,
						}}
						currentValue={{
							id: form?.fishingGearId ? form.fishingGearId : null,
							displayName: form?.fishingGear,
						}}
						// className="error"
						onSelect={(fishingGear: FishingGear) =>
							handleCreateRegistration({
								...form,
								fishingGearId: fishingGear.id as any,
								fishingGear: fishingGear.name,
							})
						}
						component={FormDropDownListWithSearch}
					/>
					<Field
						id={"CatchArea"}
						name={"Fangstfelt og sone"}
						label={t("CatchAreaAndZone")}
						value={t("ChooseCatchAreaAndZone")}
						filterable={true}
						textField={"displayName"}
						dataItemKey={"id"}
						filterApi={getCatchAreaApi}
						defaultItem={{
							displayName: t("ChooseCatchAreaAndZone"),
							id: null,
						}}
						currentValue={{
							id: form?.catchAreaId,
							displayName: form?.fishingArea,
						}}
						// className="error"
						onSelect={(catchArea: CatchArea) =>
							handleCreateRegistration({
								...form,
								catchAreaId: catchArea.id as any,
								fishingArea: catchArea.name,
							})
						}
						component={FormDropDownListWithSearch}
					/>

					<Field
						id={"Information"}
						name={"Lokalitet"}
						label={t("Location")}
						placeholder=""
						defaultValue={form.information}
						onChange={(e) => {
							handleCreateRegistration({
								...form,
								information: e.value,
							});
						}}
						component={FormInput}
					/>

					{form?.isQuaySale === 0 && selectedVessel?.ta === 1 && (
						<Field
							id={"transportation"}
							name={"transportation "}
							label={t("TransportNote")}
							selected={form.isTransportNote}
							layout={"horizontal"}
							component={FormRadioGroup}
							// selected={form.isTransportNote}
							onChange={(e) =>
								handleCreateRegistration({
									...form,
									isTransportNote: e.value,
								})
							}
							data={[
								{ label: t("Yes"), value: 1 },
								{ label: t("No"), value: 0 },
							]}
						/>
					)}
				</>
			)}
		</>
	);
};

export default FormStepOne;

const CustomDateInput: FC<{
	form: IRegistrationForm;
	setForm: (form: IRegistrationForm) => void;
}> = ({ form, setForm }) => {
	let value = "";

	if (form?.deliveryDate && form.deliveryDate !== null) {
		value = format(new Date(form.deliveryDate).toString(), DATE_TIME_FORMAT);
	}

	return (
		<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
			<Input style={{ border: "none" }} value={value} />
			{value && (
				<button
					type="button"
					onClick={() => setForm({ ...form, deliveryDate: null as any })}
					style={{
						marginLeft: "10px",
						padding: "5px 10px",
						color: "white",
						border: "none",
						cursor: "pointer",
						marginRight: "5px",
					}}
				>
					<img
						src={`/icon/cancel.svg`}
						alt="cancel"
						style={{
							width: `14px`,
							height: `14px`,
							backgroundColor: "transparent",
						}}
					/>
				</button>
			)}
		</div>
	);
};
