export  interface InfoPros{
    close: () =>void
    title: string
    show: boolean,
    description: string
    actions :{text: string, btn:() => void, disabled?:boolean, bgColor?: string, textColor?: string}[]
}

export interface FilterOption {
    fromDate?: Date|null;
    toDate?: Date|null;
    vesselRegistrationNumber?: string;
    skip: number;
    take: number;
}


export class CustomError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'CustomError';
        Object.setPrototypeOf(this, CustomError.prototype);
    }

}
