import React, { useEffect, useRef, useState } from "react";
import ItemCard from "ui/Card";
import SalesNoteCard from "./SalesNoteCard";
import AccountingSettlementCard from "./AccountingSettelmentCard";
import RightItems, { BuyerRightItems } from "./RightItems";
import { ICON_NAMES } from "utils/constant";
import { useTranslation } from "react-i18next";
import { useUserOrganizationsStore, useUserStore } from "store";
import LandingNoteCard from "./LandingNoteCard";
import { OrganizationType } from "interface/user";
import InvoiceCard from "./InvoiceCard";
import YearlyRevenueCard from "./YearlyRevenueCard";
import { getBuyerStatisticApi, getVesselStatisticApi } from "api/statistic";
import { BuyerStatistic, VesselStatistic } from "interface/statistic";
import { t } from "i18next";
import { formatNorwegianNumber } from "utils/helper";
import { usePageHeight } from "utils/hooks";

interface HeaderData {
	title: string;
	subTitle: string;
	valueUnit: string;
	currentValue: string;
	changePercent: number;
	isPercentDecrease: boolean;
}
interface HeaderItem {
	registration?: HeaderData;
	quantityPurchased?: HeaderData;
	amountPurchased?: HeaderData;
	catchAmount?: HeaderData;
	totalPaidOut?: HeaderData;
}
const HomePageItems = () => {
	const { t } = useTranslation();
	const { user } = useUserStore();
	const [headerItems, setHeaderItems] = useState<HeaderItem>();
	const { defaultOrg } = useUserOrganizationsStore();
	const vesselStatsRef = useRef<VesselStatistic>();
	const buyerStatsRef = useRef<BuyerStatistic>();
	const pageHeight = usePageHeight();

	let year = new Date().getFullYear();

	useEffect(() => {
		if (!defaultOrg) {
			return;
		}

		if (defaultOrg.type === OrganizationType.Buyer) {
			getBuyerStatisticApi({
				buyerId: defaultOrg.ledgerAccountNumber?.trim() as any,
				year,
			})
				.then((data) => {
					buyerStatsRef.current = data.data;
					setHeaderItems(
						handleBuyerHeaderItem(defaultOrg.type, data.data, year)
					);
				})
				.catch((err) => {
					console.log(err);
				});
		}

		if (defaultOrg.type === OrganizationType.Vessel) {
			getVesselStatisticApi({
				vesselId: defaultOrg.vesselId?.trim() as any,
				vesselRegistrationNumber: defaultOrg.vesselRegistrationNumber,
				year,
			})
				.then((data) => {
					console.log(data);
					vesselStatsRef.current = data.data;
					setHeaderItems(handleVesselHeaderItem(data.data, year));
				})
				.catch((err) => {
					console.log(err);
				});
		}

		if (defaultOrg.type === OrganizationType.Terminal) {
		}
	}, [defaultOrg, year]);

	if (!user || !defaultOrg) return <div></div>;
	return (
		<div style={{ display: "flex", gap: 30, height: "100%" }}>
			<div style={{ width: "100%" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "40px",
						width: "100%",
						gap: "30px",
					}}
				>
					{defaultOrg.type === OrganizationType.Vessel && (
						<>
							<ItemCard
								title={headerItems?.registration?.title as any}
								subTitle={headerItems?.registration?.subTitle as any}
								currentValue={headerItems?.registration?.currentValue as any}
								valueUnit={headerItems?.registration?.valueUnit as any}
								changePercent={headerItems?.registration?.changePercent as any}
								isPercentDecrease={
									headerItems?.registration?.isPercentDecrease as any
								}
								style={{ width: "100%" }}
							/>
							<ItemCard
								title={headerItems?.catchAmount?.title as any}
								subTitle={headerItems?.catchAmount?.subTitle as any}
								currentValue={headerItems?.catchAmount?.currentValue as any}
								valueUnit={headerItems?.catchAmount?.valueUnit as any}
								changePercent={headerItems?.catchAmount?.changePercent as any}
								isPercentDecrease={
									headerItems?.catchAmount?.isPercentDecrease as any
								}
								style={{ width: "100%" }}
							/>
						</>
					)}
					{defaultOrg.type === OrganizationType.Buyer && (
						<>
							<ItemCard
								title={headerItems?.quantityPurchased?.title as any}
								subTitle={headerItems?.quantityPurchased?.subTitle as any}
								currentValue={
									headerItems?.quantityPurchased?.currentValue as any
								}
								valueUnit={headerItems?.quantityPurchased?.valueUnit as any}
								changePercent={
									headerItems?.quantityPurchased?.changePercent as any
								}
								isPercentDecrease={
									headerItems?.quantityPurchased?.isPercentDecrease as any
								}
								style={{ width: "100%" }}
							/>
							<ItemCard
								title={headerItems?.amountPurchased?.title as any}
								subTitle={headerItems?.amountPurchased?.subTitle as any}
								currentValue={headerItems?.amountPurchased?.currentValue as any}
								valueUnit={headerItems?.amountPurchased?.valueUnit as any}
								changePercent={
									headerItems?.amountPurchased?.changePercent as any
								}
								isPercentDecrease={
									headerItems?.amountPurchased?.isPercentDecrease as any
								}
								style={{ width: "100%" }}
							/>
						</>
					)}
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "30px",
						// height: "600px",
						paddingBottom: "50px",
						// height: `calc(${pageHeight}px - 300px)`,
						// overflow: "auto",
					}}
				>
					{defaultOrg.type === OrganizationType.Buyer && (
						<>
							<div>
								<InvoiceCard />
							</div>
						</>
					)}
					{defaultOrg.type === OrganizationType.Vessel && (
						<>
							<div>
								<LandingNoteCard />
							</div>
							<div>
								<SalesNoteCard />
							</div>

							<div>
								<AccountingSettlementCard />
							</div>
							<div>
								<YearlyRevenueCard />
							</div>
						</>
					)}
					{defaultOrg.type === OrganizationType.Terminal && (
						<>
							<div>
								<LandingNoteCard />
							</div>
						</>
					)}
				</div>
			</div>
			<div>
				{defaultOrg.type === OrganizationType.Vessel && (
					<RightItems
						iconName={ICON_NAMES.Fish}
						title={t("MyCatch") + " " + year}
						vesselStatistic={vesselStatsRef.current}
					/>
				)}
				{defaultOrg.type === OrganizationType.Buyer && (
					<BuyerRightItems
						availableBalance={buyerStatsRef.current?.availableBalance}
						balance={buyerStatsRef.current?.balance}
						availableBalancePercentage={
							buyerStatsRef.current?.availableBalancePercentage
						}
					/>
				)}
			</div>
		</div>
	);
};

export default HomePageItems;

const handleBuyerHeaderItem = (
	type: OrganizationType,
	data: BuyerStatistic,
	year: number
): HeaderItem => {
	const _data: HeaderItem = {};
	if (type === OrganizationType.Buyer) {
		_data.quantityPurchased = {
			currentValue: formatNorwegianNumber(data.totalLiveWeightKg),
			changePercent: toDecimal(data.totalLiveWeightKgPercentageDifference),
			title: "Kvantum kjøpt i " + year,
			subTitle: "sammenlignet med samme periode fjoråret",
			isPercentDecrease:
				data.totalLiveWeightKg < data.lastYearTotalLiveWeightKg,
			valueUnit: "Kg",
		};
		_data.amountPurchased = {
			currentValue: formatNorwegianNumber(data.totalBuyerAmount) as any,
			changePercent: toDecimal(data.totalBuyerAmountPercentageDifference),
			title: "Beløp kjøpt for  i " + year,
			subTitle: "sammenlignet med samme periode fjoråret",
			isPercentDecrease: data.totalBuyerAmount < data.lastYearTotalBuyerAmount,
			valueUnit: "Kg",
		};
	}
	return _data;
};

const handleVesselHeaderItem = (
	data: VesselStatistic,
	year: number
): HeaderItem => {
	const _data: HeaderItem = {};
	_data.registration = {
		currentValue: formatNorwegianNumber(data?.totalLiveWeightKg),
		changePercent: toDecimal(data.totalLiveWeightKgPercentageDifference),
		title: t("RegisteredSoFarIn") + " " + year,
		subTitle: t("ComparedToLastYear"),
		isPercentDecrease: data.totalLiveWeightKg < data.lastYearTotalLiveWeightKg,
		valueUnit: "Kg",
	};

	_data.catchAmount = {
		currentValue: formatNorwegianNumber(data.totalFishermanAmount),
		changePercent: toDecimal(data.totalFishermanAmountPercentageDifference),
		title: "Fangstverdi hittl i år " + year,
		subTitle: t("ComparedToLastYear"),
		isPercentDecrease:
			data.totalFishermanAmount < data.lastYearTotalFishermanAmount,
		valueUnit: "Kr",
	};

	return _data;
};

function toDecimal(num: number, point: number = 2) {
	if (num == null || num === undefined) {
		return 0;
	}
	return parseFloat(num.toFixed(point));
}
