import { AxiosApi } from "api"
import { FilterOption } from "interface"
import { Contract } from "interface/contract"
import { ApiResponse } from "interface/response"

export interface ContractFilter extends  Omit<FilterOption, "vesselRegistrationNumber" >{
    vesselLedgerAccountNumber? : string,
    buyerLedgerAccountNumber? : string,
    contractId?: string 
    vesselId?: string,
    vesselName?: string
    buyerName?: string,
    buyerId?: string
}

export async function getContractsApi( filter:ContractFilter ) {
    const apiUri = "Contract/GetList"
    try {
        const invoices = await AxiosApi.get<ApiResponse<Contract[]>>(apiUri, {
            params: filter
        })
        return invoices.data
    } catch (error) {
        throw error
    } 
}

export async function contractConfirmationApi({confirmSign  = 1, contractId}: {contractId: number, confirmSign: number}) {
    const apiUri = "Contract/Confirmation"
    try {
        const invoices = await AxiosApi.get<ApiResponse<boolean>>(apiUri, {
            params: {confirmSign, contractId}
        })
        return invoices.data
    } catch (error) {
        throw error
    } 
}