import React, { useRef, useState } from "react";
import GridCustomPage from "ui/grid/GridPage";
import { AnnualStatementFilter, getAnnualStatementApi } from "api/accounting";
import styles from "styles.module.css";
import { t } from "i18next";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PageTypePath } from "utils/pages";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";
import { AnnualStatementGridColumnItem } from "./GridColumns";
import Filter from "./Filter";

const pageSize = 60;

const AnnualStatement = () => {
	const gridRef = useRef<HTMLDivElement>(null);
	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);
	const [filterOptions, setFilterOptions] = useState<AnnualStatementFilter>({
		take: pageSize,
		skip: 0,
		fromDate: null,
		toDate: null,
	});

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};

	const openPdfByRow = (prop: GridCellProps) => {
		const id = `${prop.dataItem.year}-${prop.dataItem.customerBusinessRegisterNumber}`;
		window.open(
			`/${PageTypePath.Pdf}/${id}/${PdfType.AnnualStatement}`,
			"_blank"
		);
	};
	return (
		<div className={styles.PageContainer}>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>{t("AnnualReport")}</legend>
			</div>
			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{ marginTop: 30 }} ref={gridRef}>
				<GridCustomPage
					dataKey="id"
					gridColumns={AnnualStatementGridColumnItem()}
					// gridHeight={600}
					// detailPage={DetailedPage}
					// handelShowRowBgColor={handelShowRowBgColor}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					exportGridAsExcelRef={excelRef}
					lastColWidth="60px"
					apiMethod={getAnnualStatementApi}
					showDetailIcon={false}
					lastColBtns={[
						{
							onclick: openPdfByRow,
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
						},
					]}
				/>
			</div>
		</div>
	);
};

export default AnnualStatement;
