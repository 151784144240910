import React, { FC, useEffect, useRef, useState } from "react";

import CustomGrid from "ui/grid/CustomGrid";
import {
	GridCellProps,
	GridColumnProps,
	GridItemChangeEvent,
} from "@progress/kendo-react-grid";
import CustomButton from "ui/Button";
import { CLASSNAME, COLOR, ICON_NAMES } from "utils/constant";
import { CustomGridCommandCell } from "ui/grid/GridCommandCell";
import { Comment } from "../utils/index";

import { t } from "i18next";
import Info from "ui/Info";
import { useInfoStore } from "store/error";
import {
	deleteNoteHeadComment,
	getLandingNoteHeadCommentsApi,
	saveNoteHeadComments,
} from "api/landingNote";

interface HeadComment extends Comment {
	inEdit?: boolean;
}
export const FormStepThree: FC<{ noteHeaderId: number }> = ({
	noteHeaderId,
}) => {
	const [noteHeadComments, setNoteHeadComments] = useState<HeadComment[]>([]);

	const deletingLineRef = useRef<HeadComment>();
	const [showDeleteLine, setShowDeleteLine] = useState(false);
	const { setError } = useInfoStore();

	useEffect(() => {
		getData();
	}, []);

	const handleOnChangeItem = (e: GridItemChangeEvent) => {
		const item = e.dataItem;
		updateItem(e.value, item.id);
		setNoteHeadComments(
			noteHeadComments.map((line) => {
				if (line.id === item.id) {
					line.description = e.value;
				}
				return line;
			})
		);
	};

	const handleDeleteItem = (data: GridCellProps) => {
		deletingLineRef.current = data.dataItem;
		setShowDeleteLine(true);
	};

	const deleteLine = () => {
		if (!deletingLineRef.current) return;
		deleteNoteHeadComment(deletingLineRef.current.id)
			.then((response) => {
				getData();
				setShowDeleteLine(!showDeleteLine);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const gridItems: GridColumnProps[] = [
		{
			field: "lineNumber",
			title: "Lnr",
			width: "40px",
			className: ` no-border`,
			editable: false,
		},
		{
			field: "description",
			title: "Notat",
			editable: true,
			className: ` no-border`,
		},
		{
			field: "",
			title: "",
			cell: (props) => (
				<CustomGridCommandCell
					{...props}
					style={{ display: "flex", justifyContent: "flex-end" }}
					onclick={handleDeleteItem}
					iconName={ICON_NAMES.Delete}
				/>
			),
			width: "60px",
		},
	];

	const getData = () => {
		getLandingNoteHeadCommentsApi(noteHeaderId)
			.then((response) => {
				setNoteHeadComments(
					response.data.map((line) => ({ ...line, inEdit: true }))
				);
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const addNewItem = () => {
		saveNoteHeadComments({ id: 0, noteHeaderId, description: "" })
			.then((response) => {
				getData();
			})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	const updateItem = (description: string, id: number) => {
		saveNoteHeadComments({ id, noteHeaderId, description })
			.then((response) => {})
			.catch((err) => {
				if (err?.response?.data) {
					setError(err?.response?.data.returnText);
				} else {
					setError(err.message);
				}
			});
	};

	return (
		<div>
			<CustomGrid
				gridColumns={gridItems}
				data={noteHeadComments}
				editField="inEdit"
				className={CLASSNAME.TableNumeric + " " + CLASSNAME.HideOverFlow}
				onItemChange={handleOnChangeItem}
			/>
			<div style={{ marginTop: "20px" }}>
				<CustomButton
					label={t("NewLine") + " +"}
					onClick={addNewItem}
					bgColor={COLOR.Green}
					color="white"
				/>
			</div>
			<Info
				close={() => setShowDeleteLine(!showDeleteLine)}
				title={t("DeleteLine")}
				show={showDeleteLine}
				description={t("DeleteLineConfirmation")}
				actions={[
					{ text: t("Cancel"), btn: () => setShowDeleteLine(!showDeleteLine) },
					{
						text: t("Yes"),
						btn: deleteLine,
						bgColor: "red",
						textColor: "white",
					},
				]}
			/>
		</div>
	);
};

export default FormStepThree;
