import { LandingNoteHead } from "interface/landingNote"

export interface Comment {
    id : number
    noteHeadId? : number
    lineNumber : number
    description : string
    noteLineId?: number
}

export const requirementFields ={
    diaryNumber: false,
    tripNumber: false,
    firstCatchDate: false,
    lastCatchDate: false,
    catchYear: false,
    crewSize: false,
    norwegianCrewSize: false,
    vesselOfficersNorwegian: false,
    departureDate: false,
    arrivalDate: false,
    quotaTypeCode: false,
    quotaNationCode: false,
    landingNationCode: false,
    partialLandingId: false,
    vesselCaptainsName: false,
    productionFacilityId: false
}


  export const handleRequirementCheck = (form: LandingNoteHead) => {
    const requirementCheck = { ...requirementFields }; 
    requirementCheck.diaryNumber = form.diaryNumber !== null 
    requirementCheck.tripNumber = form.tripNumber !== null 
    requirementCheck.firstCatchDate = form.firstCatchDate !== null && form.firstCatchDate !== "";
    requirementCheck.lastCatchDate = form.lastCatchDate !== null && form.lastCatchDate !== "";
    requirementCheck.catchYear = form.catchYear !== null
    requirementCheck.crewSize = form.crewSize !== null
    requirementCheck.norwegianCrewSize = form.norwegianCrewSize !== null 
    requirementCheck.vesselOfficersNorwegian = form.vesselOfficersNorwegian !== null
    requirementCheck.departureDate = form.departureDate !== null && form.departureDate !== "";
    requirementCheck.arrivalDate = form.arrivalDate !== null && form.arrivalDate !== "";
    requirementCheck.quotaTypeCode = form.quotaTypeCode !== null && form.quotaTypeCode !== "";
    requirementCheck.quotaNationCode = form.quotaNationCode !== null && form.quotaNationCode !== "";
    requirementCheck.landingNationCode = form.landingNationCode !== null && form.landingNationCode !== "";
    requirementCheck.partialLandingId = form.partialLandingId !== null
    requirementCheck.vesselCaptainsName = form.vesselCaptainsName !== null && form.vesselCaptainsName !== "";
    requirementCheck.productionFacilityId = form.productionFacilityId !== null && form.productionFacilityId !== "";
  
    return requirementCheck;
  };
  
  
 export  const areAllRequirementsMet = (requirementCheck: typeof requirementFields) => {
    return Object.values(requirementCheck).every((value) => value === true);
  };
  