import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { Popover } from "@progress/kendo-react-tooltip";
import { FC, useRef, useState } from "react";

/**
 * `CellPopover` is a custom cell component for Kendo Grid that displays a popover
 * containing the full content of the cell when the cell's content is truncated (i.e., ellipsis is applied).
 *
 * Features:
 * - Detects if the cell content is truncated using the `scrollWidth` and `clientWidth` properties.
 * - Displays a popover when the user hovers over a truncated cell, showing the full text.
 *
 * Props:
 * @param {GridCustomCellProps} props - Props passed by Kendo Grid, including data and field information.
 */
const CellPopover: FC<GridCustomCellProps> = (props) => {
	const anchor = useRef<HTMLDivElement>(null);

	const [show, setShow] = useState(false);

	const isEllipsisActive = (): boolean => {
		if (anchor.current) {
			return anchor.current.scrollWidth > anchor.current.clientWidth;
		}
		return false;
	};

	const onHover = () => {
		if (!isEllipsisActive()) {
			return;
		}
		setShow(true);
	};

	return (
		<td
			className={props.className}
			onMouseOver={() => onHover()}
			onMouseLeave={() => setShow(false)}
		>
			<div ref={anchor}>{props.dataItem[props?.field as any]}</div>

			<Popover show={show} anchor={anchor.current} position={"bottom"}>
				{props.dataItem[props?.field as any]}
			</Popover>
		</td>
	);
};

export default CellPopover;
