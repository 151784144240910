import {
	GridCellProps,
	GridColumnProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import CustomDropDownList from "ui/dropDown";
import { getPackagesApi, getProductsApi } from "api/common";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import React, { FC } from "react";
import { GridCommandCellMultiple } from "ui/grid/GridCommandCell";
import { NumericalCell } from "ui/grid/NumericalCellInput";
import { FooterAggregateSum } from "ui/grid/GridFooters";
import { CLASSNAME, ICON_NAMES } from "utils/constant";
import i18n from "../../../../i18n";

let t = i18n.t;

interface StepTwoGridColumnProps {
	update: (data: any, type?: "product" | "packaging" | "other") => void;
	openNoteModal: (props: GridCellProps) => void;
	onSelectPackage: (data: any) => void;
	startDeletingLine: (data: any) => void;
	data: any[];
}

export const stepTwoGridColumns = (prop: StepTwoGridColumnProps) => {
	const gridItems: GridColumnProps[] = [
		{
			field: "productId",
			title: t("Product"),
			editable: true,
			className: `no-border col-min-width`,
			//	width: "350px",
			cell: (props) => {
				let code = props.dataItem[props.field as any] || null;
				let display = props.dataItem["product"];
				let value = {} as any;
				if (code !== null) {
					value.id = code;
					value.name = display;
				}
				return (
					<td
						className="no-border col-min-width"
						style={{
							paddingLeft: "1px !important",
						}}
					>
						<CustomDropDownList
							data={[]}
							value={value}
							onSelect={(data) => {
								props.dataItem[props.field as any] = data.id;
								props.dataItem["product"] = data.name;
								prop.update(props.dataItem, "product");
							}}
							serverFilterApi={getProductsApi}
							defaultItem={{ id: null, name: "Velg Vare" }}
							filterable={true}
							serverFilter={true}
							textField={"name"}
							dataItemKey={"id"}
							// showError={
							// 	!prop.hasMeetRequirement(props.dataItem, props.field as any) &&
							// 	prop.showError
							// }
							borderColor="none"
						/>
					</td>
				);
			},
		},
		{
			field: "packagingCode",
			title: t("Packaging"),
			className: ` no-border`,
			//	width: "350px",
			editable: true,

			cell: (props) => {
				let code = props.dataItem[props.field as any] || null;
				let display = props.dataItem["packaging"];

				let value = {} as any;
				if (code !== null) {
					value.code = code;
					value.name = display;
				}
				return (
					<td className="no-border">
						<CustomDropDownList
							data={[]}
							value={value}
							// onChange={onChange}
							filterable={true}
							serverFilter={true}
							onSelect={(data) => {
								props.dataItem[props.field as any] = data.code;
								props.dataItem["packaging"] = data.name;
								prop.onSelectPackage(props.dataItem);
								// prop.update(props.dataItem, "packaging");
							}}
							defaultItem={{ code: null, name: t("ChoosePackaging") }}
							textField={"name"}
							dataItemKey={"code"}
							itemRender={itemRender}
							// showError={
							// 	!prop.hasMeetRequirement(props.dataItem, props.field as any) &&
							// 	prop.showError
							// }
							serverFilterApi={getPackagesApi}
							borderColor="none"
						/>
					</td>
				);
			},
		},
		{
			field: "numberOfUnits",
			title: t("Units"),
			editable: true,
			width: "110px",

			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={0} />,
				edit: {
					text: (props) => {
						return <NumericalCell {...props} decimalScale={0} />;
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					field={"numberOfUnits"}
				/>
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "numberOfPallets",
			title: "Ant.Pal.",
			editable: true,
			width: "110px",

			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={0} />,
				edit: {
					text: (props) => {
						return <NumericalCell {...props} decimalScale={0} />;
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					field={"numberOfPallets"}
				/>
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "numberOfPieces",
			title: t("Pieces"),
			editable: true,
			width: "110px",

			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={0} />,
				edit: {
					text: (props) => {
						return <NumericalCell {...props} decimalScale={0} />;
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					field={"numberOfPieces"}
				/>
			),
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},

		{
			field: "averageLiveWeightKg",
			title: "SNITTVEKT  (Kg)",
			editable: true,
			//format: "{0:d}",
			width: "120px",
			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={4} />,
				edit: {
					text: (props) => {
						return (
							<NumericalCell
								{...props}
								decimalScale={3}
								fixedDecimalScale={true}
								// showError={
								// 	!prop.hasMeetRequirement(
								// 		props.dataItem,
								// 		props.field as any
								// 	) && prop.showError
								// }
							/>
						);
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					decimalScale={3}
					fixedDecimalScale={true}
					field={"averageLiveWeightKg"}
				/>
			),

			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "thawingSampleLiveWeightKg",
			title: "KV.TINEPRØVE",
			editable: true,
			//format: "{0:d}",
			width: "120px",
			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={4} />,
				edit: {
					text: (props) => {
						return (
							<NumericalCell
								{...props}
								decimalScale={3}
								fixedDecimalScale={true}
								// showError={
								// 	!prop.hasMeetRequirement(
								// 		props.dataItem,
								// 		props.field as any
								// 	) && prop.showError
								// }
							/>
						);
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					decimalScale={3}
					fixedDecimalScale={true}
					field={"thawingSampleLiveWeightKg"}
				/>
			),

			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "netLiveWeightKg",
			title: "BRUTTEO",
			editable: true,
			//format: "{0:d}",
			width: "120px",
			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={4} />,
				edit: {
					text: (props) => {
						return (
							<NumericalCell
								{...props}
								decimalScale={3}
								fixedDecimalScale={true}
								// showError={
								// 	!prop.hasMeetRequirement(
								// 		props.dataItem,
								// 		props.field as any
								// 	) && prop.showError
								// }
							/>
						);
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					decimalScale={3}
					fixedDecimalScale={true}
					field={"netLiveWeightKg"}
				/>
			),

			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "grossLiveWeightKg",
			title: "NETTO VEKT",
			editable: true,
			//format: "{0:d}",
			width: "120px",
			cells: {
				data: (props) => <NumericalCell {...props} decimalScale={4} />,
				edit: {
					text: (props) => {
						return (
							<NumericalCell
								{...props}
								decimalScale={3}
								fixedDecimalScale={true}
								// showError={
								// 	!prop.hasMeetRequirement(
								// 		props.dataItem,
								// 		props.field as any
								// 	) && prop.showError
								// }
							/>
						);
					},
				},
			},
			footerCell: (data) => (
				<FooterAggregateSum
					props={data}
					data={prop.data}
					decimalScale={3}
					fixedDecimalScale={true}
					field={"grossLiveWeightKg"}
				/>
			),

			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "price",
			title: t("Price"),
			editable: true,
			width: "120px",
			cells: {
				data: (props) => (
					<NumericalCell decimalScale={3} fixedDecimalScale={true} {...props} />
				),
				edit: {
					text: (props) => {
						return (
							<NumericalCell
								{...props}
								decimalScale={3}
								fixedDecimalScale={true}
								// showError={
								// 	!prop.hasMeetRequirement(
								// 		props.dataItem,
								// 		props.field as any
								// 	) && prop.showError
								// }
							/>
						);
					},
				},
			},

			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} show_error`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "",
			title: "",
			width: "100px",
			cell: (props) => (
				<GridCommandCellMultiple
					{...props}
					style={{}}
					items={[
						{
							onclick: prop.openNoteModal,
							iconName: ICON_NAMES.Note,
						},
						{
							onclick: prop.startDeletingLine,
							iconName: ICON_NAMES.Delete,
						},
					]}
				/>
			),
		},
	];

	return gridItems;
};

const itemRender = (
	li: React.ReactElement<HTMLLIElement>,
	itemProps: ListItemProps
) => {
	const itemChildren = [
		<span key={1} style={{ width: "40px" }}>
			&nbsp; {itemProps.dataItem.code}{" "}
		</span>,
		<span key={2}>&nbsp; {itemProps.dataItem.name} </span>,
	];

	return React.cloneElement(li, li.props, itemChildren);
};
const NumericalHeader: FC<GridHeaderCellProps> = (props) => {
	return <span>{props.title}</span>;
};
