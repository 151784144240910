import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import i18n from "../../../i18n";
import CellPopover from "ui/grid/DescriptionCell";
import DotCell from "ui/grid/DotCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const SalesConfirmationGridColumn = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "id",
			title: "Salgs nr",
		},
		{
			field: "",
			title: "",
			width: "30px",
			headerClassName: "no-border",
			cell: (props) => <DotCell isGreen={props.dataItem.status === 3} />,
		},
		{
			field: "id",
			title: "Salgs nr",

			width: "100px",
		},
		{
			field: "salesTime",
			title: "Åpningsdato",
			width: "110px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.salesTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},

		{
			field: "confirmationText",
			title: "Tekst",
			cell: (props) => <CellPopover {...props} />,
		},
		{ field: "vesselRegistrationId", title: "fartøymerke", width: "120px" },
		{
			field: "vesselName",
			title: "Fartøynavn",
			cell: (props) => <CellPopover {...props} />,
		},
		{
			field: "buyerName",
			title: "Kjøpernavn",
			cell: (props) => <CellPopover {...props} />,
		},
		{
			field: "registrationTime",
			title: "Innmeldt dato",
			width: "120px",
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.registrationTime}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{ field: "salesType", title: "Type", width: "80px" },

		{
			field: "netLiveWeightKg",
			title: t("TotalWeight"),
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kg"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
		{
			field: "buyerAmount",
			title: "BuyerAmount",
			width: "120px",

			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const salesConfirmationDetailGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},
	{
		// field: "lineNumber",
		title: t("Line"),
		width: "120px",
		cell: (props) => (
			<span style={{ paddingLeft: "10px" }}>{props.dataItem.lineNumber}</span>
		),
	},

	{ field: "product", title: t("Product") },

	{
		field: "numberOfPieces",
		title: t("Pieces"),
		cell: (props) => <NumericalCell {...props} unit={""} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "price",
		title: t("Price"),
		width: "120px",
		cell: (props) => <NumericalCell {...props} unit={"kr"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: t("Weight"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "netLiveWeightKg",
		title: t("Value"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kg"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];
