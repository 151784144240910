import { CustomError, InfoPros } from 'interface';
import { create } from 'zustand';

interface InfoStore  {
    info: InfoPros |  null,
    error: CustomError | null
    setError: (error: CustomError) => void;
    setInfo: (info: InfoPros) => void;
    resetStore: () => void
}

//Todo: add actual field
export const useInfoStore = create<InfoStore >()((set) => ({
    info:  null,
    error: null,
    setError: (error: CustomError) => set(() => ({ error})),
    setInfo: (info: InfoPros) => set(() => ({ info})),
    resetStore: () => set(() => ({ info : null, error: null })),

}));

