import { GridColumnProps } from "@progress/kendo-react-grid";
import { CLASSNAME } from "utils/constant";
import { GrideDateFormatter } from "ui/Date";
import { FC } from "react";
//import styles from "styles.module.css";
import i18n from "../../../../i18n";
import { CheckMarkCell } from "ui/grid/CheckMarkCell";
import { NumericalCell, NumericalHeader } from "ui/grid/GridCommandCell";

let t = i18n.t;

export const SettlementGridColumnItem = () => {
	const gridItems: GridColumnProps[] = [
		{
			//This wont show up
			field: "registrationDate",
			title: "Dato",
		},
		// {
		// 	field: "",
		// 	title: "",
		// 	width: "30px",
		// 	headerClassName: "no-border",
		// 	cell: (props) => <DotCell value={props.dataItem.status} />,
		// },
		{
			field: "invoiceNumber",
			title: t("SettlementNumber"),
			// width: "100px",
			headerClassName: "no-border",
		},
		{
			field: "customerName",
			title: t("Vessel"),
			//width: "150px",
			headerClassName: "no-border",
		},
		{
			field: "invoiceDate",
			title: t("SettlementDate"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.invoiceDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
			// width: "110px",
		},

		{
			field: "printedDate",
			title: t("PrintDate"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.printedDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},
		{ field: "printedBy", title: t("PrintedBy") },
		{
			field: "ehfAccessResponse",
			title: "EHF",
			width: "40px",
			cell: (props) => (
				<CheckMarkCell
					{...props}
					checked={props.dataItem["invoiceSentDate"] !== null ? true : false}
				/>
			),
		},
		{
			field: "dueDate",
			title: t("PaymentDate"),
			headerClassName: "no-border",
			cell: (props) => (
				<GrideDateFormatter
					date={props.dataItem.dueDate}
					formatType={"dd.MM.yyyy"}
				/>
			),
		},

		{
			field: "grossValue",
			title: t("Value"),
			width: "120px",
			cell: (props) => <NumericalCell {...props} unit={"kr"} />,
			headerClassName: CLASSNAME.TableNumeric,
			className: `${CLASSNAME.TableNumeric} no-border`,
			headerCell: (props) => <NumericalHeader {...props} />,
		},
	];
	return gridItems;
};

export const settlementDetailGridItems: GridColumnProps[] = [
	{
		//this will not be render
		field: "lineNumber",
		title: "Linje",
	},

	{
		field: "description1",
		title: t("Description"),
		//width: "150px",
		headerClassName: "no-border",
	},

	{
		field: "exchangeRate",
		title: t("Rate"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "costPrice",
		title: t("BaseAmount"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kr"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "invoicePrice",
		title: t("Amount"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kr"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		field: "totalThisYear",
		title: t("TotalThisYear"),
		width: "120px",

		cell: (props) => <NumericalCell {...props} unit={"kr"} />,
		headerClassName: CLASSNAME.TableNumeric,
		className: `${CLASSNAME.TableNumeric} no-border`,
		headerCell: (props) => <NumericalHeader {...props} />,
	},
	{
		width: "40px",
		className: `${CLASSNAME.TableNumeric} no-border`,
	},
];
