import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GridCustomPage from "ui/grid/GridPage";

import styles from "styles.module.css";
import Filter from "./Filter";
import { PageTypePath } from "utils/pages";
import {
	settlementDetailGridItems,
	SettlementGridColumnItem,
} from "./utilities/GridColumns";
import {
	getSettlementLinesApi,
	getSettlementsApi,
	SettlementFilter,
} from "api/accounting";
import { PdfType } from "utils/PdfType";
import { ICON_NAMES } from "utils/constant";
import CustomDetailedPage from "ui/grid/DetailPage";

const pageSize = 60;

export default function Settlements() {
	const { t } = useTranslation();

	const gridRef = useRef<HTMLDivElement>(null);

	const [filterOptions, setFilterOptions] = useState<SettlementFilter>({
		take: pageSize,
		skip: 0,
		fromDate: null,
		toDate: null,
	});

	const pdfRef = useRef<GridPDFExport>(null);
	const excelRef = useRef<ExcelExport>(null);

	const exportGridAsPDF = () => {
		if (pdfRef.current !== null) {
			pdfRef.current.save();
		}
	};

	const exportGridAsExcel = () => {
		if (excelRef.current !== null) {
			excelRef.current.save();
		}
	};
	const openPdfByRow = (prop: GridCellProps) => {
		window.open(
			`/${PageTypePath.Pdf}/${prop.dataItem.id}/${PdfType.Settlement}`,
			"_blank"
		);
	};

	return (
		<div className={styles.PageContainer}>
			<div className="page-header">
				<legend className={styles.BoxHeadingLarge}>{t("Settlements")}</legend>
			</div>

			<div>
				<Filter
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
					exportAsPDF={exportGridAsPDF}
					exportAsExcel={exportGridAsExcel}
					pageSize={pageSize}
				/>
			</div>
			<div style={{ marginTop: 30 }} ref={gridRef}>
				<GridCustomPage
					dataKey="id"
					gridColumns={SettlementGridColumnItem()}
					detailPage={(prop) => (
						<CustomDetailedPage
							grids={settlementDetailGridItems}
							dataApi={() => getSettlementLinesApi(prop.dataItem.id)}
						/>
					)}
					filter={filterOptions}
					exportGridAsPDFRef={pdfRef}
					exportGridAsExcelRef={excelRef}
					lastColWidth="90px"
					apiMethod={getSettlementsApi}
					lastColBtns={[
						{
							onclick: openPdfByRow,
							iconName: ICON_NAMES.Download,
							alwaysShow: true,
						},
					]}
				/>
			</div>
		</div>
	);
}
