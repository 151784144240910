import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { Vessel } from "interface/vessel";
import React from "react";

const VesselRenderItem = (
	li: React.ReactElement<HTMLLIElement>,
	itemProps: ListItemProps
) => {
	const data: Vessel = itemProps.dataItem;

	const item = (
		<div style={{ display: "flex", gap: "40px" }}>
			<div style={{ display: "flex", gap: "15px" }}>
				<span style={{ display: "block", width: "80px" }}>{data.rn}</span>
				<span style={{ display: "block", width: "80px" }}>{data.cs}</span>
				<span style={{ display: "block", width: "150px" }}>{data.nm}</span>
			</div>
			<div style={{ display: "flex", gap: "20px" }}>
				<span style={{ display: "block" }}>{data.fr}</span>
				<span style={{ display: "block" }}>{data.to}</span>
			</div>
		</div>
	);

	return React.cloneElement(li, li.props, item);
};

export default VesselRenderItem;
