import { FC } from "react";
import { COLOR } from "utils/constant";

const DotCell: FC<{ value?: number; isGreen?: boolean; color?: string }> = ({
	value,
	isGreen,
	color,
}) => {
	let _color = value === 1 ? COLOR.StatusGreen : "red";
	if (isGreen !== undefined) {
		_color = isGreen ? COLOR.StatusGreen : "red";
	}
	if (color) {
		_color = color;
	}
	return (
		<td className="no-border">
			<div
				style={{
					width: 12,
					height: 12,
					borderRadius: 12,
					backgroundColor: _color,
				}}
			></div>
			{/* <Skeleton
				animation={SkeletonAnimation}
				shape="circle"
				style={{
					width: 12,
					height: 12,

					backgroundColor: color,
				}}
			/> */}
		</td>
	);
};
export default DotCell;
